import { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { colors } from "../../../config/colors/colors";
import { updateBreadcrumb, updateIsLoading } from "../../../redux/action";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../service/axiosInstane";
import { snackBarColors } from "../../../config/constants";

const AddendumReport = () => {
  const dispatch = useDispatch();
  const [snackBarOpen, setSnackBarOpen] = useState({
    value: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    const crumbs = [
      {
        name: "Reports",
      },
      {
        name: "Addendum Report",
      },
    ];
    dispatch(updateBreadcrumb(crumbs));
  }, []);

  const exportAddendumData = () => {
    dispatch(updateIsLoading(true));
    axiosInstance
      .post("/exportexceladdendumreport")
      .then((response) => {
        if (response.status === 200) {
          const csvData = response.data;
          const blob = new Blob([csvData], { type: "text/csv" });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Addendum_Report`;
          a.click();
          URL.revokeObjectURL(url);
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: response.data.message,
            type: snackBarColors.success,
          });
        } else {
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: "Addendum Report not available",
            type: snackBarColors.error,
          });
        }
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        setSnackBarOpen({
          ...snackBarOpen,
          value: true,
          message: err.response.data.message,
          type: snackBarColors.error,
        });
        dispatch(updateIsLoading(false));
      });
  };

  return (
    <>
      <div className="bg-white shadow-lg">
        <div className="flex flex-col py-20 items-center justify-center">
          <div className={`my-4 text-2xl font-semibold ${colors.primaryText}`}>
            <Button
              title={"Export Addendum Report"}
              type={"button"}
              color={"primary"}
              onClick={exportAddendumData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddendumReport;
