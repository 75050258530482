import { useEffect, useRef, useState } from "react";
import { colors } from "../../../config/colors/colors";
import { updateBreadcrumb, updateIsLoading } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../service/axiosInstane";
import { useLocation, useParams } from "react-router-dom";
import CheckBox from "../../../components/CheckBox/CheckBox";
import RadioButton from "../../../components/RadioButton/RadioButton";
import DataTable from "../../../components/DataTable/DataTable";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import TextArea from "../../../components/TextArea/TextArea";
import AlertDialog from "../../../components/Dialog/Dialog";
import sliceTime from "../../../helpers/sliceTime";
import ReduxStateProps from "../../../redux/Redux.props";
import { snackBarColors } from "../../../config/constants";
import { useFormik } from "formik";
import SnackBar from "../../../components/SnackBar/SnackBar";
import React from "react";

type DownloadDocProps = {
  documentId: string;
  assetIds: string | null;
  documentName: string | null;
  documentType: string;
  documentFileName: string;
  documentPath: string;
  documentDescription: null;
  createdById: number;
  createdBy: string;
  updatedById: number | null;
  updatedBy: string;
  createdOn: string;
  updatedOn: string;
  files: string[];
};

const projectTitleColumns = [
  {
    id: "title",
    label: "Title",
    minWidth: 100,
    align: "left",
  },
  {
    id: "identifier",
    label: "Identifier",
    minWidth: 70,
    align: "left",
  },
  {
    id: "contentType",
    label: "Content Type",
    minWidth: 70,
    align: "left",
  },
];

const documentColumns = [
  {
    id: "documentFileName",
    label: "File Name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "documentDescription",
    label: "Description",
    minWidth: 70,
    align: "left",
  },
  {
    id: "documentType",
    label: "Document Type",
    minWidth: 70,
    align: "left",
  },
  {
    id: "createdBy",
    label: "Created By",
    minWidth: 70,
    align: "left",
  },
  {
    id: "createdOn",
    label: "Created On",
    minWidth: 70,
    align: "left",
  },
  {
    id: "updatedBy",
    label: "Last Updated By",
    minWidth: 70,
    align: "left",
  },
  {
    id: "updatedOn",
    label: "Updated On",
    minWidth: 70,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 70,
    align: "left",
  },
];

type AssetDetailsProps = {
  projectId: number;
  projectCode: string | null;
  assetId: number;
  contentClass: string;
  contentType: string;
  lbplReferenceNumber: string;
  permissionEditor: string;
  sourceTitleOrPublicationName: string;
  sourceChapterOrPageOrLocation: string;
  ourChapterOrUnit: string;
  assetCaption: string;
  sourceName: string;
  sourceAssetId: string;
  sourcePublicationAuthor: string;
  ourPageOrLocation: string | null;
  copyrightName: string;
  placement: string;
  pricingType: string;
  pricing: string;
  creditLine: string;
  permissionRequired: string;
  permissionNotRequiredReason: string;
  rightsClass: string | null;
  reuseStatus: string | null;
  locationRelease: string;
  modelRelease: string;
  ownership: string;
  performanceRelease: string;
  licenseStartDate: string;
  licenseExpDate: string;
  permissionRequestedDate: string;
  permissionChaseDate1: string;
  permissionChaseDate2: string;
  permissionChaseDate3: string;
  rightsAsRequested: boolean;
  supportingSupplements: boolean;
  foreignLanguageTranslations: boolean;
  promotionalUse: string;
  numberofEditions: string;
  derivatives: boolean;
  abridgements: boolean;
  briefEditions: boolean;
  splitEditions: boolean;
  adaptations: boolean;
  customVersions: boolean;
  otherVersions: boolean;
  reuseOutsideofOriginalProgram: boolean;
  permittedUsers: string;
  reuseofParts: boolean;
  chapterOrUnit: boolean;
  portionsOfChapterOrUnit: boolean;
  outofContextReuseAllowed: boolean;
  formatOrQuantityAsRequested: boolean;
  allMediaDistribution: boolean;
  printDistribution: boolean;
  digitalDistribution: boolean;
  distributionOthers: string;
  allMediaQuantityLimit: string | null;
  printQuantityLimit: string | null;
  digitalQuantityLimit: string | null;
  territoriesAsRequested: boolean;
  territoriesWorldRights: boolean;
  territoriesWorldRightsExclNorthamerica: boolean;
  territoriesNonWorldRights: boolean;
  territoriesOthers: string | null;
  languagesAsRequested: boolean;
  languagesAll: boolean;
  languagesOthers: string;
  rightsRestrictions: string;
  assetTitles: null;
  isAssetForAllTitles: boolean;
  generalComments: string;
  permissionStatus: string;
  assetDocuments: null;
  createdBy: number;
  updatedBy: number;
  createdByWithDate: string | null;
  updatedByWithDate: string | null;
};
const ViewAssetDetails = () => {
  const dropdownList = useSelector(
    (state: ReduxStateProps) => state.projectDropdownList
  );
  const userName = useSelector((state: ReduxStateProps) => state.user.userName);
  const userId = useSelector((state: ReduxStateProps) => state.user.id);
  const dispatch = useDispatch();
  const { assetId } = useParams();
  const { state } = useLocation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState("");
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [updateDoc, setUpdateDoc] = useState("");
  const [docId, setDocId] = useState<string | number>();
  const [snackBarOpen, setSnackBarOpen] = useState({
    value: false,
    message: "",
    type: "",
  });
  const [documentRequest, setDocumentRequest] = useState({
    documentId: "",
    assetIds: [Number(assetId)],
    documentName: "",
    documentType: "",
    documentFileName: "",
    documentPath: "",
    documentDescription: "",
    createdById: userId,
    createdBy: userName,
    updatedById: 0,
    updatedBy: "",
  });
  const [responseData, setResponseData] = useState<AssetDetailsProps>({
    projectId: 0,
    projectCode: null,
    assetId: 0,
    contentClass: "",
    contentType: "",
    lbplReferenceNumber: "",
    permissionEditor: "",
    sourceTitleOrPublicationName: "",
    sourceChapterOrPageOrLocation: "",
    ourChapterOrUnit: "",
    assetCaption: "",
    sourceName: "",
    sourceAssetId: "",
    sourcePublicationAuthor: "",
    ourPageOrLocation: null,
    copyrightName: "",
    placement: "",
    pricingType: "",
    pricing: "",
    creditLine: "",
    permissionRequired: "",
    permissionNotRequiredReason: "",
    rightsClass: null,
    reuseStatus: null,
    locationRelease: "",
    modelRelease: "",
    ownership: "",
    performanceRelease: "",
    licenseStartDate: "",
    licenseExpDate: "",
    permissionRequestedDate: "",
    permissionChaseDate1: "",
    permissionChaseDate2: "",
    permissionChaseDate3: "",
    rightsAsRequested: false,
    supportingSupplements: false,
    foreignLanguageTranslations: false,
    promotionalUse: "",
    numberofEditions: "",
    derivatives: false,
    abridgements: false,
    briefEditions: false,
    splitEditions: false,
    adaptations: false,
    customVersions: false,
    otherVersions: false,
    reuseOutsideofOriginalProgram: false,
    permittedUsers: "",
    reuseofParts: false,
    chapterOrUnit: false,
    portionsOfChapterOrUnit: false,
    outofContextReuseAllowed: false,
    formatOrQuantityAsRequested: false,
    allMediaDistribution: false,
    printDistribution: false,
    digitalDistribution: false,
    distributionOthers: "",
    allMediaQuantityLimit: null,
    printQuantityLimit: null,
    digitalQuantityLimit: null,
    territoriesAsRequested: false,
    territoriesWorldRights: false,
    territoriesWorldRightsExclNorthamerica: false,
    territoriesNonWorldRights: false,
    territoriesOthers: null,
    languagesAsRequested: false,
    languagesAll: false,
    languagesOthers: "",
    rightsRestrictions: "",
    assetTitles: null,
    isAssetForAllTitles: false,
    generalComments: "",
    permissionStatus: "",
    assetDocuments: null,
    createdBy: 0,
    updatedBy: 0,
    createdByWithDate: null,
    updatedByWithDate: null,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const crumbs = [
      {
        name: "Projects",
      },
      {
        path: "/title/projects_home",
        name: "My Projects",
      },
      {
        path: `/title/project_summary/${state.projectId}`,
        name: "Project Summary Details",
      },
      {
        path: `/title/view_project_asset_details/${assetId}`,
        name: "Project Asset Details",
      },
    ];
    dispatch(updateBreadcrumb(crumbs));
    getAssetDetails();
  }, []);

  const getAssetDetails = () => {
    dispatch(updateIsLoading(true));
    axiosInstance
      .get(`/projects/${state.projectId}/assets/${assetId}`)
      .then((response) => {
        if (response.status === 200) {
          setResponseData(response.data);
        }
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateIsLoading(false));
      });
  };
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const assetDetailsLeft = [
    {
      label: "Content Class",
      value: responseData.contentClass,
    },
    {
      label: "Content Type",
      value: responseData.contentType,
    },
    {
      label: "Caption/Desc",
      value: responseData.assetCaption,
    },
    {
      label: "Sourc Title/Publication Name",
      value: responseData.sourceTitleOrPublicationName,
    },
    {
      label: "Sourc Chapter/Page No./Location",
      value: responseData.sourceChapterOrPageOrLocation,
    },
    {
      label: "Our Chapter/Unit",
      value: responseData.ourChapterOrUnit,
    },
    {
      label: "Source Name",
      value: responseData.sourceName,
    },
    {
      label: "Source Asset Id",
      value: responseData.sourceAssetId,
    },
    {
      label: "Credit Line",
      value: responseData.creditLine,
    },
  ];
  const assetDetailsRight = [
    {
      label: "LBPL Reference No.",
      value: responseData.lbplReferenceNumber,
    },
    {
      label: "Permissions Editor",
      value: responseData.permissionEditor,
    },
    {
      label: "Source Publication Author",
      value: responseData.sourcePublicationAuthor,
    },
    {
      label: "Our Page No./Asset Location",
      value: responseData.ourPageOrLocation,
    },
    {
      label: "Copyright Name",
      value: responseData.copyrightName,
    },
    {
      label: "Placement",
      value: responseData.placement,
    },
    {
      label: "Pricing",
      value: `${responseData.pricing !== null ? responseData.pricing : "-"} ${" "} ${
        responseData.pricingType !== null
          ? responseData.pricingType?.slice(0, 3)
          : ""
      }`,
    },
  ];

  const rightsGrantedLeft = [
    {
      label: "Permission Required",
      value: `${responseData.permissionRequired === "True" ? "Yes" : "No"}`,
    },
    {
      label: "Rights Class",
      value: responseData.rightsClass,
    },
    {
      label: "Location Release",
      value: responseData.locationRelease,
    },
    {
      label: "% Ownership",
      value: responseData.ownership,
    },
    {
      label: "License Start Date",
      value: sliceTime(responseData.licenseStartDate),
    },
    {
      label: "License Exp Date",
      value: sliceTime(responseData.licenseExpDate),
    },
    {
      label: "Permission Requested Date",
      value: sliceTime(responseData.permissionRequestedDate),
    },
  ];
  const rightsGrantedRight = [
    {
      label: "Permission Not Required Reason",
      value: responseData.permissionNotRequiredReason,
    },
    {
      label: "Re-use Status",
      value: responseData.reuseStatus,
    },
    {
      label: "Model Release",
      value: responseData.modelRelease,
    },
    {
      label: "Performance Release",
      value: responseData.performanceRelease,
    },
    {
      label: "Permission Chase Date 1",
      value: sliceTime(responseData.permissionChaseDate1),
    },
    {
      label: "Permission Chase Date 2",
      value: sliceTime(responseData.permissionChaseDate2),
    },
    {
      label: "Permission Chase Date 3",
      value: sliceTime(responseData.permissionChaseDate3),
    },
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    const files = e.target.files;
    formik.setFieldValue("docfile", e.currentTarget.files?.[0]);
    if (files && files.length > 0) {
      const file = files?.[0];
      formik.setFieldValue("documentFileName", file.name);
    } else {
      setFile("null");
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleClose = () => {
    setOpen(false);
    setIsSubmit(false);
  };

  const getInitialValues = () => {
    if (updateDoc === "Create") {
      return {
        documentId: "",
        assetIds: [Number(assetId)],
        documentName: "",
        documentType: "",
        documentFileName: "",
        documentPath: "",
        documentDescription: "",
        createdById: userId,
        createdBy: userName,
        updatedById: 0,
        updatedBy: "",
        docfile: "",
      };
    } else {
      return {
        projectId: state.projectId,
        documentId: Number(docId),
        documentName: "",
        documentType: "",
        documentFileName: "",
        documentPath: "",
        documentDescription: "",
        updatedBy: userId,
      };
    }
  };

  const initialValues = getInitialValues();

  const validate = (values: { documentFileName: string }) => {
    const errors: any = {};
    if (!values.documentFileName) {
      errors.documentFileName = "Required";
    }
    return errors;
  };

  const onSubmit = () => {
    if (updateDoc === "Create") {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const createPayload = {
        DocumentId: "",
        AssetIds: [Number(assetId)],
        DocumentName: "",
        DocumentType: formik.values.documentType,
        DocumentFileName: formik.values.documentFileName,
        DocumentPath: "",
        DocumentDescription: formik.values.documentDescription,
        CreatedById: userId,
        CreatedBy: userName,
        UpdatedById: userId,
        UpdatedBy: userName,
        CreatedOn: "",
        UpdatedOn: "",
        Files: formik.values.docfile,
      };
      dispatch(updateIsLoading(true));
      axiosInstance
        .post(`/projects/${state.projectId}/assetdocuments`, createPayload, config)
        .then((response) => {
          if (response.status === 200) {
            setOpen(false);
            setSnackBarOpen({
              ...snackBarOpen,
              value: true,
              message: response.data.message,
              type: snackBarColors.success,
            });
          }
          getAssetDetails();
          formik.setValues({ ...initialValues });
          dispatch(updateIsLoading(false));
        })
        .catch((err) => {
          setOpen(false);
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: err.response.data.message,
            type: snackBarColors.error,
          });
          dispatch(updateIsLoading(false));
        });
    } else {
      const updatePayload = {
        projectId: state.projectId,
        documentId: Number(docId),
        documentName: "",
        documentType: formik.values.documentType,
        documentFileName: formik.values.documentFileName,
        documentPath: formik.values.documentPath,
        documentDescription: formik.values.documentDescription,
        updatedBy: userId,
      };
      dispatch(updateIsLoading(true));
      axiosInstance
        .put(`/projects/${state.projectId}/updatedocument`, updatePayload)
        .then((response) => {
          if (response.status === 200) {
            setOpen(false);
            setSnackBarOpen({
              ...snackBarOpen,
              value: true,
              message: response.data.message,
              type: snackBarColors.success,
            });
          }
          getAssetDetails();
          formik.setValues({ ...initialValues });
          dispatch(updateIsLoading(false));
        })
        .catch((err) => {
          setOpen(false);
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: err.response.data.message,
            type: snackBarColors.error,
          });
          dispatch(updateIsLoading(false));
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onDocEdit = (arg: string | number, index: number) => {
    setDocId(arg);
    setUpdateDoc("Edit");
    const obj = {
      projectId: 0,
      assetId: Number(assetId),
      documentId: Number(arg),
    };
    setOpen(true);
    dispatch(updateIsLoading(true));
    axiosInstance
      .post("/projects/getdocuments", obj)
      .then((response) => {
        if (response.status === 200) {
          formik.setFieldValue("documentFileName", response.data.documentFileName);
          formik.setFieldValue("documentType", response.data.documentType);
          formik.setFieldValue(
            "documentDescription",
            response.data.documentDescription
          );
          formik.setFieldValue("documentPath", response.data.documentPath);
        }
        setUpdateDoc("");
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        setUpdateDoc("");
        dispatch(updateIsLoading(false));
      });
  };

  const deleteRecord = (arg: string | number, index: number) => {
    const obj = {
      assetId: assetId,
      projectId: 0,
      documentId: arg,
      deletedBy: userId,
    };
    dispatch(updateIsLoading(true));
    axiosInstance
      .delete("/projects/deletedocument", { data: obj })
      .then((response) => {
        if (response.status === 200) {
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: response.data.message,
            type: snackBarColors.success,
          });
        }
        getAssetDetails();
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        setSnackBarOpen({
          ...snackBarOpen,
          value: true,
          message: err.response.data.message,
          type: snackBarColors.error,
        });
        dispatch(updateIsLoading(false));
      });
  };

  const downloadDoc = (arg: DownloadDocProps) => {
    const fileUrl = arg.documentPath;
    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = arg.documentFileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const attachDocDialog = () => {
    return (
      <div className="p-5">
        <div className="flex justify-center font-bold text-xl">{`${
          updateDoc === "Create" ? "Attach Document" : "Update Document"
        }`}</div>
        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between w-[450px]">
                <div className="font-semibold">
                  File Name<span className="text-[red]">*</span>
                </div>
                <Input
                  type={"text"}
                  name="documentFileName"
                  disabled={true}
                  value={formik.values.documentFileName}
                  errors={isSubmit ? formik.errors.documentFileName : ""}
                  onChange={formik.handleChange}
                />
                <input
                  ref={inputRef}
                  className="hidden"
                  type="file"
                  onChange={handleFileChange}
                />
              </div>
              {updateDoc === "Create" && (
                <div className="mt-2">
                  <Button
                    title={"Browse"}
                    type={"button"}
                    color={"normal"}
                    height="h-[30px]"
                    onClick={() => handleClick()}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center justify-between w-[450px]">
              <div className="font-semibold">{"Document Type"}</div>
              <Dropdown
                name="documentType"
                isStringValue={true}
                value={
                  formik.values.documentType === "" ? "" : formik.values.documentType
                }
                onChange={formik.handleChange}
                options={dropdownList.assetDocumentType}
                initialOption={{
                  id: "",
                  name: "",
                }}
                placeholder="Select"
              />
            </div>
            <div className="flex items-center justify-between w-[450px]">
              <div className="font-semibold">{"Document Description"}</div>
              <TextArea
                name="documentDescription"
                value={formik.values.documentDescription}
                onChange={formik.handleChange}
              />
            </div>
            <div className="flex justify-center space-x-2">
              <Button
                title={`${updateDoc === "Create" ? "Save" : "Update"}`}
                type={"submit"}
                color={"primary"}
                onClick={() => setIsSubmit(true)}
              />
              <Button
                title={"Cancel"}
                type={"button"}
                color={"danger"}
                onClick={() => handleClose()}
              />
            </div>
          </form>
        </div>
      </div>
    );
  };

  const handleAttach = () => {
    setUpdateDoc("Create");
    setOpen(true);
    formik.setFieldValue("documentFileName", initialValues.documentFileName);
    formik.setFieldValue("documentType", initialValues.documentType);
    formik.setFieldValue("documentDescription", initialValues.documentDescription);
  };

  return (
    <>
      <div className="w-full">
        <div
          className={`font-bold my-2 text-[26px] ${colors.primaryText} font-sans`}>
          {"Project Asset Details"}
        </div>

        <div className="shadow-lg bg-white p-4">
          <div
            className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
            Project Details
          </div>
          <div className="flex items-center space-x-20 px-4 py-2 text-md">
            <div className="flex items-center my-1">
              <div className="text-[#5e5d58]">Project ID</div>
              <div className=" font-semibold">
                :{" "}
                {`${
                  state.projectCode === null || state.projectCode === ""
                    ? "-"
                    : state.projectCode
                }`}
              </div>
            </div>
            <div className="flex items-center my-1">
              <div className="text-[#5e5d58]">Project Name</div>
              <div className=" font-semibold">
                :{" "}
                {`${
                  state.projectName === null || state.projectName === ""
                    ? "-"
                    : state.projectName
                }`}
              </div>
            </div>
          </div>
          <div className="px-4 py-2 text-md"></div>
          <div
            className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
            Asset Details
          </div>
          <div className="px-4 py-2 text-md">
            <div className="flex space-x-20">
              <div className="w-1/2">
                {assetDetailsLeft.map((item, index) => {
                  return (
                    <div className="flex space-x-8 w-full my-1" key={index}>
                      <div className="w-[280px] text-[#5e5d58]">{item.label}</div>
                      <div className="flex">
                        <div>:</div>
                        <div className="pl-1 font-semibold w-[280px]">
                          {`${
                            item.value === null || item.value === ""
                              ? "-"
                              : item.value
                          }`}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="w-1/2">
                {assetDetailsRight.map((item, index) => {
                  return (
                    <div
                      className="flex space-x-8 items-center w-full my-1"
                      key={index}>
                      <div className="w-[280px] text-[#5e5d58]">{item.label}</div>
                      <div className="flex">
                        <div>:</div>
                        <div className="pl-1 font-semibold w-[280px]">
                          {`${
                            item.value === null || item.value === ""
                              ? "-"
                              : item.value
                          }`}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
            Rights Granted
          </div>
          <div className="px-4 py-2 text-md">
            <div className="flex space-x-20">
              <div className="w-1/2">
                {rightsGrantedLeft.map((item, index) => {
                  return (
                    <div
                      className="flex space-x-8 items-center w-full my-1"
                      key={index}>
                      <div className="w-[280px] text-[#5e5d58]">{item.label}</div>
                      <div className="flex">
                        <div>:</div>
                        <div className="pl-1 font-semibold w-[280px]">
                          {`${
                            item.value === null || item.value === ""
                              ? "-"
                              : item.value
                          }`}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="w-1/2">
                {rightsGrantedRight.map((item, index) => {
                  return (
                    <div
                      className="flex space-x-8 items-center w-full my-1"
                      key={index}>
                      <div className="w-[280px] text-[#5e5d58]">{item.label}</div>
                      <div className="flex">
                        <div>:</div>
                        <div className="pl-1 font-semibold w-[280px]">
                          {`${
                            item.value === null || item.value === ""
                              ? "-"
                              : item.value
                          }`}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="text-sm mt-4">
            <div className="flex items-center bg-gray-300 px-4 space-x-10 font-semibold ">
              <div className="text-black font-semibold">Rights</div>
              <div className="flex items-center">
                <CheckBox
                  size="small"
                  name="rightsAsRequested"
                  value="true"
                  disabled
                  checked={responseData.rightsAsRequested}
                />
                <div>As Requested</div>
              </div>
            </div>
            <div className="px-4 py-2 text-md">
              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">Supporting Supplements</div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="supportingSupplements"
                    value="yes"
                    disabled
                    checked={responseData.supportingSupplements}
                  />
                  <label className="text-zinc-900">Yes</label>
                </div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="supportingSupplements"
                    value="no"
                    disabled
                    checked={!responseData.supportingSupplements}
                  />
                  <label className="text-zinc-900">No</label>
                </div>
              </div>
              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">Foreign Language Translations</div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="foreignLanguageTranslations"
                    value="yes"
                    disabled
                    checked={responseData.foreignLanguageTranslations}
                  />
                  <label className="text-zinc-900">Yes</label>
                </div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="foreignLanguageTranslations"
                    value="no"
                    disabled
                    checked={!responseData.foreignLanguageTranslations}
                  />
                  <label className="text-zinc-900">No</label>
                </div>
              </div>
              <div>
                <div className="flex space-x-10 items-center w-full my-1">
                  <div className="w-[280px] text-[#5e5d58]">Number of Editions</div>
                  <div className="flex">
                    <div>:</div>
                    <div className="pl-1 font-semibold w-[800px]">
                      {`${
                        responseData.numberofEditions === null ||
                        responseData.numberofEditions === ""
                          ? "-"
                          : responseData.numberofEditions
                      }`}
                    </div>
                  </div>
                </div>
                <div className="flex space-x-10 items-center w-full my-1">
                  <div className="w-[280px] text-[#5e5d58]">Promotional Use</div>
                  <div className="flex">
                    <div>:</div>
                    <div className="pl-1 font-semibold w-[800px]">
                      {`${
                        responseData.promotionalUse === null ||
                        responseData.promotionalUse === ""
                          ? "-"
                          : responseData.promotionalUse
                      }`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">Derivatives</div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="derivatives"
                    value="yes"
                    disabled
                    checked={responseData.derivatives}
                  />
                  <label className="text-zinc-900">Yes</label>
                </div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="derivatives"
                    value="no"
                    disabled
                    checked={!responseData.derivatives}
                  />
                  <label className="text-zinc-900">No</label>
                </div>
              </div>
              <div className="flex  items-center my-2">
                <CheckBox
                  size="small"
                  value="abridgements"
                  disabled
                  checked={responseData.abridgements}
                />
                <div>Abridgements</div>
                <CheckBox
                  size="small"
                  value="briefEditions"
                  disabled
                  checked={responseData.briefEditions}
                />
                <div>Brief Editions</div>
                <CheckBox
                  size="small"
                  value="splitEditions"
                  disabled
                  checked={responseData.splitEditions}
                />
                <div>Split Editions</div>
                <CheckBox
                  size="small"
                  value="adaptations"
                  disabled
                  checked={responseData.adaptations}
                />
                <div>Adaptations </div>
                <CheckBox
                  size="small"
                  value="customVersion"
                  disabled
                  checked={responseData.customVersions}
                />
                <div>Custom Versions</div>
                <CheckBox
                  size="small"
                  value="versions"
                  disabled
                  checked={responseData.otherVersions}
                />
                <div>Versions </div>
              </div>

              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">Reuse outside of original program/course</div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="reuseOutside"
                    value="yes"
                    disabled
                    checked={responseData.reuseOutsideofOriginalProgram}
                  />
                  <label className="text-zinc-900">Yes</label>
                </div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="reuseOutside"
                    value="no"
                    disabled
                    checked={!responseData.reuseOutsideofOriginalProgram}
                  />
                  <label className="text-zinc-900">No</label>
                </div>
              </div>
              <div>
                <div className="flex space-x-10 items-center w-full my-1">
                  <div className="w-[280px] text-[#5e5d58]">Permitted Users</div>
                  <div className="flex">
                    <div>:</div>
                    <div className="pl-1 font-semibold w-[800px]">
                      {`${
                        responseData.permittedUsers === null ||
                        responseData.permittedUsers === ""
                          ? "-"
                          : responseData.permittedUsers
                      }`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">
                  Reuse of Parts of the title containing the asset
                </div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="reuseOfParts"
                    value="yes"
                    disabled
                    checked={responseData.reuseofParts}
                  />
                  <label className="text-zinc-900">Yes</label>
                </div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="reuseOfParts"
                    value="no"
                    disabled
                    checked={!responseData.reuseofParts}
                  />
                  <label className="text-zinc-900">No</label>
                </div>
              </div>
              <div className="flex  items-center my-2">
                <CheckBox
                  size="small"
                  disabled
                  checked={responseData.chapterOrUnit}
                />
                <div>Chapter/Unit</div>
                <CheckBox
                  size="small"
                  disabled
                  checked={responseData.portionsOfChapterOrUnit}
                />
                <div>Portions of a Chapter/Unit</div>
              </div>
              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">Out of Context Reuse Allowed</div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="outOfContextReuseAllowed"
                    value="yes"
                    disabled
                    checked={responseData.outofContextReuseAllowed}
                  />
                  <label className="text-zinc-900">Yes</label>
                </div>
                <div className="flex items-center">
                  <RadioButton
                    size="small"
                    name="outOfContextReuseAllowed"
                    value="no"
                    disabled
                    checked={!responseData.outofContextReuseAllowed}
                  />
                  <label className="text-zinc-900">No</label>
                </div>
              </div>
            </div>
            <div className="flex items-center bg-gray-300 px-4 space-x-10 font-semibold mt-1">
              <div className="text-black font-semibold">Format / Quantity</div>
              <div className="flex items-center">
                <CheckBox
                  size="small"
                  name="FormatRequest"
                  value="FormatRequest"
                  disabled
                  checked={responseData.formatOrQuantityAsRequested}
                />
                <div>As Requested</div>
              </div>
            </div>
            <div className="flex items-center px-4 py-2 text-md">
              <div>
                <div className="flex items-center">
                  <CheckBox
                    size="small"
                    disabled
                    checked={responseData.allMediaDistribution}
                  />
                  <div>All Media</div>
                  <div>{responseData.allMediaQuantityLimit}</div>
                </div>
              </div>
              <div>
                <div className="flex items-center">
                  <CheckBox
                    size="small"
                    disabled
                    checked={responseData.printDistribution}
                  />
                  <div>Print</div>
                  <div>{responseData.printQuantityLimit}</div>
                </div>
              </div>
              <div>
                <div className="flex items-center">
                  <CheckBox
                    size="small"
                    disabled
                    checked={responseData.digitalDistribution}
                  />
                  <div>Digital</div>
                  <div>{responseData.digitalQuantityLimit}</div>
                </div>
              </div>
            </div>
            <div className="px-4 py-2 text-md">
              <div className="flex items-center w-full my-1">
                <div className="w-[150px] text-[#5e5d58]">Other(s)</div>
                <div className="flex">
                  <div>:</div>
                  <div className="pl-1 font-semibold w-[800px]">
                    {`${
                      responseData.distributionOthers === null ||
                      responseData.distributionOthers === ""
                        ? "-"
                        : responseData.distributionOthers
                    }`}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center bg-gray-300 px-4 space-x-10 font-semibold mt-1">
              <div className="text-black font-semibold">Territories</div>
              <div className="flex items-center">
                <CheckBox
                  size="small"
                  name="TerritoriesRequest"
                  value="TerritoriesRequest"
                  disabled
                  checked={responseData.territoriesAsRequested}
                />
                <div>As Requested</div>
              </div>
            </div>
            <div className="flex items-center px-4 py-2 text-md">
              <CheckBox
                size="small"
                disabled
                checked={responseData.territoriesWorldRights}
              />
              <div>World Rights</div>
              <CheckBox
                size="small"
                disabled
                checked={responseData.territoriesWorldRightsExclNorthamerica}
              />
              <div>World Rights Excluding North America</div>
              <CheckBox
                size="small"
                disabled
                checked={responseData.territoriesNonWorldRights}
              />
              <div>Non World Rights</div>
            </div>
            <div className="px-4 py-2 text-md">
              <div className="flex items-center w-full my-1">
                <div className="w-[150px] text-[#5e5d58]">Other(s)</div>
                <div className="flex">
                  <div>:</div>
                  <div className="pl-1 font-semibold w-[800px]">
                    {`${
                      responseData.territoriesOthers === null ||
                      responseData.territoriesOthers === ""
                        ? "-"
                        : responseData.territoriesOthers
                    }`}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center bg-gray-300 px-4 space-x-10 font-semibold mt-1">
              <div className="text-black font-semibold">Language(s)</div>
              <div className="flex items-center">
                <CheckBox
                  size="small"
                  name="LanguageRequest"
                  value="LanguageRequest"
                  disabled
                  checked={responseData.languagesAsRequested}
                />
                <div>As Requested</div>
              </div>
            </div>
            <div className="flex items-center px-4 py-2 text-md">
              <CheckBox
                size="small"
                name="allLanguage"
                value="allLanguage"
                disabled
                checked={responseData.languagesAll}
              />
              <div>All</div>
            </div>
            <div className="px-4 py-2 text-md">
              <div className="flex space-x-10 items-center w-full my-1">
                <div className="w-[150px] text-[#5e5d58]">Other(s)</div>
                <div className="flex">
                  <div>:</div>
                  <div className="pl-1 font-semibold w-[800px]">
                    {`${
                      responseData.languagesOthers === null ||
                      responseData.languagesOthers === ""
                        ? "-"
                        : responseData.languagesOthers
                    }`}
                  </div>
                </div>
              </div>
              <div className="flex space-x-10 items-center w-full my-1">
                <div className="w-[150px] text-[#5e5d58]">Rights Restrictions</div>
                <div className="flex">
                  <div>:</div>
                  <div className="pl-1 font-semibold w-[800px]">
                    {`${
                      responseData.rightsRestrictions === null ||
                      responseData.rightsRestrictions === ""
                        ? "-"
                        : responseData.rightsRestrictions
                    }`}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold mt-2`}>
            Title Assignment
          </div>
          <div className="px-4 py-2">
            {responseData.assetTitles !== null ? (
              <DataTable
                columns={projectTitleColumns}
                rows={responseData.assetTitles}
                searchColumn={"title"}
                showDelete={false}
                showView={false}
                showEdit={false}
                checkBoxShow={false}
                checkBoxValue="titleId"
                primaryKey="titleId"
                rowsPerPage={rowsPerPage}
                totalRowsCount={0}
                page={page}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                sortingHandling={() => {
                  return null;
                }}
              />
            ) : (
              <div className="text-red-600">No Asset Title Data</div>
            )}
          </div>
          <div
            className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
            Others
          </div>
          <div className="px-4 py-2 text-md">
            <div className="flex space-x-10 items-center w-full my-1">
              <div className="w-[280px] text-[#5e5d58]">General Comments</div>
              <div className="flex">
                <div>:</div>
                <div className="pl-1 font-semibold w-[800px]">
                  {`${
                    responseData.generalComments === null ||
                    responseData.generalComments === ""
                      ? "-"
                      : responseData.generalComments
                  }`}
                </div>
              </div>
            </div>
            <div className="flex space-x-10 items-center w-full my-1">
              <div className="w-[280px] text-[#5e5d58]">Permission Status</div>
              <div className="flex">
                <div>:</div>
                <div className="pl-1 font-semibold w-[800px]">
                  {`${
                    responseData.permissionStatus === null ||
                    responseData.permissionStatus === ""
                      ? "-"
                      : responseData.permissionStatus
                  }`}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${colors.primaryBackground} text-white px-4 py-2 font-semibold flex items-center justify-between`}>
            <div className="pl-1">Documents</div>
            <div
              className="bg-white text-[blue] px-2 py-1 border border-[blue] uppercase text-sm cursor-pointer"
              onClick={() => handleAttach()}>
              Attach
            </div>
          </div>
          <div className="px-4 py-2">
            {responseData.assetDocuments !== null ? (
              <DataTable
                columns={documentColumns}
                rows={responseData.assetDocuments}
                showDelete={true}
                showView={false}
                showEdit={true}
                onEditHandler={onDocEdit}
                onDeleteHandler={deleteRecord}
                onDownloadHandler={downloadDoc}
                showDownload={true}
                checkBoxShow={false}
                primaryKey="documentId"
                rowsPerPage={rowsPerPage}
                totalRowsCount={0}
                page={page}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                sortingHandling={() => {
                  return null;
                }}
              />
            ) : (
              <div className="text-red-600">No Asset Document Data</div>
            )}
          </div>
        </div>
        <div className="px-5 flex items-center">
          <div className="flex space-x-5 items-center w-1/2 my-1">
            <div className="text-[#5e5d58] font-bold">Created By/Date</div>
            <div className="flex">
              <div>:</div>
              <div className="pl-1 font-semibold">
                {`${
                  responseData.createdByWithDate === null ||
                  responseData.createdByWithDate === ""
                    ? "-"
                    : responseData.createdByWithDate
                }`}
              </div>
            </div>
          </div>
          <div className="flex space-x-5 items-center w-1/2 my-1">
            <div className="text-[#5e5d58] font-bold">Last Updated By/Date</div>
            <div className="flex">
              <div>:</div>
              <div className="pl-1 font-semibold">
                {`${
                  responseData.updatedByWithDate === null ||
                  responseData.updatedByWithDate === ""
                    ? "-"
                    : responseData.updatedByWithDate
                }`}
              </div>
            </div>
          </div>
        </div>
        <div>
          {open ? (
            <AlertDialog open={open} handleClose={handleClose}>
              {attachDocDialog()}
            </AlertDialog>
          ) : null}
        </div>
      </div>
      <div>
        <SnackBar
          isOpen={snackBarOpen.value}
          handleClose={() => setSnackBarOpen({ ...snackBarOpen, value: false })}
          message={snackBarOpen.message}
          type={snackBarOpen.type}
        />
      </div>
    </>
  );
};

export default ViewAssetDetails;
