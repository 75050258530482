import { Checkbox } from "@mui/material";
import React from "react";

interface CheckboxProps {
  color?:
    | "primary"
    | "default"
    | "info"
    | "secondary"
    | "error"
    | "success"
    | "warning";
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  size?: "small" | "medium";
  checked?: boolean;
  value?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}
const CheckBox = (props: CheckboxProps) => {
  const { color, name, onChange, size, checked, value, onClick, disabled } = props;
  return (
    <div data-testid="checkbox-wrapper">
      {" "}
      <Checkbox
        color={color}
        name={name}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
        value={value}
        inputProps={{ "aria-label": "controlled" }}
        size={size}
        data-testid="checkbox-content"
      />
    </div>
  );
};

export default CheckBox;
