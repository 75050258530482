import { legacy_createStore as createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Reducer from "../reducer";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";


const persistConfig = {
  key: "rms-root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, Reducer);
const store = createStore(persistedReducer, composeWithDevTools());
const persistor = persistStore(store);

export { store, persistor };
