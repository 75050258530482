import Radio from "@mui/material/Radio";
interface RadioButtonProps {
  value?: string;
  name?: string;
  checked?: boolean;
  onClick?: () => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  size?: "small" | "medium" | undefined;
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "default"
    | undefined;
  disabled?: boolean;
}

const RadioButton = (props: RadioButtonProps) => {
  const { onChange, value, name, checked, size, color, onClick, disabled } = props;
  return (
    <div data-testid="radio-wrapper">
      {" "}
      <Radio
        data-testid="radio-content"
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
        size={size}
        color={color}
        onClick={onClick}
        disabled={disabled}
      />
    </div>
  );
};

export default RadioButton;
