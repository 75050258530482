import {
  pubCenterList,
  titleDropdownListProps,
  titleSeriesList,
  projectDropdownListProps,
  projectSummaryDetailsProps,
  projectAssetDataProps,
  titleLicenseDropdownListProps,
  assignmentContractDropdownValuesProps,
} from "../Redux.props";

export const INCREMENT = "INCREMENT";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_IS_LOADING = "UPDATE_IS_LOADING";
export const UPDATE_CURRENTTAB = "UPDATE_CURRENTTAB";
export const UPDATE_BREADCRUMB = "UPDATE_BREADCRUMB";
export const SEARCH_CLICK = "SEARCH_CLICK";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const UPDATE_USER_INFORMATION = "UPDATE_USER_INFORMATION";
export const UPDATE_REFRESHTOKEN = "UPDATE_REFRESHTOKEN";
export const UPDATE_TITLE_DROPDOWN_LIST = "UPDATE_TITLE_DROPDOWN_LIST";
export const UPDATE_TITLE_SERIES_LIST = "UPDATE_TITLE_SERIES_LIST";
export const UPDATE_PUB_CENTER_LIST = "UPDATE_PUB_CENTER_LIST";
export const UPDATE_AUTHOR_LIST = "UPDATE_AUTHOR_LIST";
export const UPDATE_PEOJECT_DROPDOWN_LIST = "UPDATE_PEOJECT_DROPDOWN_LIST";
export const UPDATE_PROJECT_SUMMARY_DETAILS = "UPDATE_PROJECT_SUMMARY_DETAILS";
export const UPDATE_PROJECT_ASSET_DETAILS = "UPDATE_PROJECT_ASSET_DETAILS";
export const UPDATE_TITLE_LICENSE_DROPDOWN_LIST =
  "UPDATE_TITLE_LICENSE_DROPDOWN_LIST";
export const UPDATE_ASSIGNMENT_CONTRACT_DROPDOWN_VALUES =
  "UPDATE_ASSIGNMENT_CONTRACT_DROPDOWN_VALUES";

export function increment() {
  return {
    type: INCREMENT,
  };
}

export function update_token(token: string) {
  return {
    type: UPDATE_TOKEN,
    payload: token,
  };
}

export function updateIsLoading(status: boolean) {
  return {
    type: UPDATE_IS_LOADING,
    payload: status,
  };
}

export function updateCurrentTab(tab: string) {
  return {
    type: UPDATE_CURRENTTAB,
    payload: tab,
  };
}

export function updateBreadcrumb(crumbs: any) {
  return {
    type: UPDATE_BREADCRUMB,
    payload: crumbs,
  };
}

export function updateSearchClick(data: string) {
  return {
    type: SEARCH_CLICK,
    payload: data,
  };
}

export function updateUserRole(role: string) {
  return {
    type: UPDATE_USER_ROLE,
    payload: role,
  };
}

export function updateUserInformation(user: any) {
  return {
    type: UPDATE_USER_INFORMATION,
    payload: user,
  };
}

export function update_refreshToken(token: string) {
  return {
    type: UPDATE_REFRESHTOKEN,
    payload: token,
  };
}

export function updateTitleDropdownList(data: titleDropdownListProps) {
  return {
    type: UPDATE_TITLE_DROPDOWN_LIST,
    payload: data,
  };
}

export function updateTitleSeriesList(data: titleSeriesList[]) {
  return {
    type: UPDATE_TITLE_SERIES_LIST,
    payload: data,
  };
}

export function updatePubCenterList(data: pubCenterList[]) {
  return {
    type: UPDATE_PUB_CENTER_LIST,
    payload: data,
  };
}

export function updateAuthorList(data: any) {
  return {
    type: UPDATE_AUTHOR_LIST,
    payload: data,
  };
}

export function updateProjectDropdownList(data: projectDropdownListProps) {
  return {
    type: UPDATE_PEOJECT_DROPDOWN_LIST,
    payload: data,
  };
}

export function updateProjectSummaryDetails(data: projectSummaryDetailsProps) {
  return {
    type: UPDATE_PROJECT_SUMMARY_DETAILS,
    payload: data,
  };
}

export function updateProjectAssetDetails(data: projectAssetDataProps) {
  return {
    type: UPDATE_PROJECT_ASSET_DETAILS,
    payload: data,
  };
}

export function updateTitleLicenseDropdownList(data: titleLicenseDropdownListProps) {
  return {
    type: UPDATE_TITLE_LICENSE_DROPDOWN_LIST,
    payload: data,
  };
}

export function updateAssignmentContractDropdownValues(
  data: assignmentContractDropdownValuesProps
) {
  return {
    type: UPDATE_ASSIGNMENT_CONTRACT_DROPDOWN_VALUES,
    payload: data,
  };
}
