interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  name?: string;
  rows?: number;
  columns?: number;
  disabled?: boolean;
  width?: string;
  placeholder?: string;
  value?: string;
  errors?: string;
  required?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

const TextArea = (props: TextAreaProps) => {
  const {
    label,
    name,
    rows,
    columns,
    disabled,
    width,
    placeholder,
    value,
    errors,
    required,
    onChange,
    ...rest
  } = props;
  return (
    <div className="my-2 mx-2 text-sm wrapper" data-testid="textarea-wrapper">
      <div className="text-sm mb-1">
        <label className="firstName text-zinc-900" data-testid="textarea-label">
          {label}
        </label>
        {required && <span className="text-red-500 ml-1">*</span>}
      </div>
      <div>
        {" "}
        <textarea
          {...rest}
          placeholder={placeholder}
          name={name}
          rows={rows}
          cols={columns}
          onChange={onChange}
          disabled={disabled}
          className={`${
            width ? width : "min-w-[300px]"
          } border rounded-sm outline-none p-2 ${disabled && "bg-gray-400"}`}
          value={value}
          data-testid="textarea-field"
        />
      </div>
      {errors ? (
        <div className="text-sm text-[red]" data-testid="error">
          {errors}
        </div>
      ) : null}
    </div>
  );
};

export default TextArea;
