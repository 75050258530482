import React, { useEffect, useState } from "react";
import { updateBreadcrumb, updateIsLoading } from "../../../../redux/action/index";
import { useDispatch } from "react-redux";
import { colors } from "../../../../config/colors/colors";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../service/axiosInstane";
import DataTable from "../../../../components/DataTable/DataTable";
import { TitleLicenseAddendumDetailsProps } from "../../TitleLicenseAddendum/TitleLicenseAddendumDetails/TitleLicenseAddendumDetailsProps";

const impressionsColumnNames = [
  {
    id: "impressionNo",
    label: "Impression Number",
    minWidth: 70,
    align: "left",
  },
  {
    id: "titleCodes",
    label: "Title Code",
    minWidth: 70,
    align: "left",
  },
  {
    id: "impressionDate",
    label: "Impression Date",
    minWidth: 70,
    align: "left",
  },
  {
    id: "tmsid",
    label: "TMSID",
    minWidth: 100,
    align: "left",
  },
  {
    id: "quantityPrinted",
    label: "Quantity Printed",
    minWidth: 100,
    align: "left",
  },
];

const LicenseAddendumDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [noData, setNoData] = useState("");
  const [responseData, setResponseData] = useState<TitleLicenseAddendumDetailsProps>(
    {
      addendumId: 0,
      licenseId: 0,
      addendumCode: "",
      firstImpressionWithinDate: null,
      addendumQuantity: "",
      totalNumberofImpressions: 0,
      addendumDate: "",
      expiryDate: null,
      addendumRemarks: "",
      contactPerson: "",
      publishingCompany: "",
      listofAddendumTitleDetails: [
        {
          titleId: 0,
          titleCode: "",
          lbplisbn: "",
          workingTitle: "",
          titleLicenseId: 0,
          titleLicenseCode: "",
        },
      ],
      listofAddendumRoyaltySlab: [
        {
          royaltySlabId: 0,
          quantityFrom: 0,
          quantityTo: 0,
          royaltyPercent: 0,
        },
      ],
      listofUploadedAddendums: [
        {
          addendumIds: 0,
          fileIds: 0,
          fileNames: "",
          filePaths: "",
        },
      ],
      listofUploadedLicenses: [
        {
          titleLicenseId: 0,
          fileId: 0,
          fileName: "",
          filePath: "",
        },
      ],
      licenseAddendumListofImpressions: [
        {
          impressionId: 0,
          impressionNo: 0,
          titleCodes: "",
          tmsid: "",
          quantityPrinted: 0,
          impressionDate: "",
        },
      ],
    }
  );

  const ContractDetails = [
    {
      label: "Publishing Company",
      value: responseData.publishingCompany,
    },
    {
      label: "Contact Person",
      value: responseData.contactPerson,
    },
    {
      label: "Addendum Date",
      value: responseData.addendumDate,
    },
    {
      label: "Expiry Date",
      value: responseData.expiryDate,
    },
    {
      label: "Remarks",
      value: responseData.addendumRemarks,
    },
  ];

  const licenseTerms = [
    {
      label: "Addendum Code",
      value: responseData.addendumCode,
    },
    {
      label: "First Impression Within Date",
      value: responseData.firstImpressionWithinDate,
    },
    {
      label: "Addendum Quantity",
      value: responseData.addendumQuantity,
    },
    {
      label: "Total Number of Impressions",
      value: responseData.totalNumberofImpressions,
    },
  ];

  useEffect(() => {
    getTitleLicenseAddendumDetails();
  }, []);

  const getTitleLicenseAddendumDetails = () => {
    dispatch(updateIsLoading(true));
    axiosInstance
      .get(`/titlelicenseaddendum/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setResponseData(response.data);
          setNoData("");
        }
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message === "No Data Found") {
          setNoData("No Data Found");
        }
        dispatch(updateIsLoading(false));
      });
  };

  const handleClick = (filePath: string) => {
    window.location.href = filePath;
  };

  return (
    <div className="w-full ">
      <div className={`font-bold text-[26px] p-4 ${colors.primaryText} font-sans`}>
        {"Title License Addendum Details"}
      </div>

      <div className="shadow-lg bg-white p-4">
        <div
          className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
          Title Details
        </div>
        <div className="shadow-lg border-[gray] p-2">
          <div className="flex items-center justify-between">
            <div className="font-bold w-1/2 text-slate-800">Title License Code</div>
            <div className="font-bold w-1/2 text-slate-800">Title Code</div>
            <div className="font-bold w-1/2 text-slate-800">LBPL ISBN</div>
            <div className="font-bold w-1/2 text-slate-800">Working Title</div>
          </div>
          {responseData.listofAddendumTitleDetails !== null ? (
            <div>
              {responseData.listofAddendumTitleDetails.map((item, index: number) => {
                return (
                  <div
                    className="flex items-center justify-between"
                    key={item.titleId}>
                    <div className="w-1/2 text-slate-800">
                      {item.titleLicenseCode}
                    </div>
                    <div className="w-1/2 text-slate-800">{item.titleCode}</div>
                    <div className="w-1/2 text-slate-800">{item.lbplisbn}</div>
                    <div className="w-1/2 text-slate-800">{item.workingTitle}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="bg-gray-200 mt-1 px-4 py-2 text-red-500 font-semibold">
              No Title Data
            </div>
          )}
        </div>
        <div
          className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
          Contract Details
        </div>
        <div className="px-4 py-2 text-md">
          {ContractDetails.map((item, index) => {
            return (
              <div className="flex space-x-8 items-center w-full my-1" key={index}>
                <div className="w-[280px] text-[#5e5d58]">{item.label}</div>
                <div className=" font-semibold">
                  :{" "}
                  {`${item.value === null || item.value === "" ? "-" : item.value}`}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
          {"License Terms"}
        </div>
        <div className="px-4 py-2 text-md">
          {licenseTerms.map((item, index) => {
            return (
              <div className="flex space-x-8 items-center w-full my-1" key={index}>
                <div className="w-[280px] text-[#5e5d58]">{item.label}</div>
                <div className=" font-semibold">
                  :{" "}
                  {`${item.value === null || item.value === "" ? "-" : item.value}`}
                </div>
              </div>
            );
          })}
        </div>
        <div className="bg-[#38738a] mt-1 px-4 py-2 text-white font-semibold">
          {"Uploaded Contracts (License)"}
        </div>
        <div className="shadow-lg border-[gray] p-2">
          <div className="flex items-center justify-between">
            <div className="font-bold w-1/2 text-slate-800">Sl No.</div>
            <div className="font-bold w-1/2 text-slate-800">Filename</div>
            <div className="font-bold w-1/2 text-slate-800">Action</div>
          </div>
          {responseData.listofUploadedLicenses !== null ? (
            <div>
              {responseData.listofUploadedLicenses.map((item, index: number) => {
                return (
                  <div
                    className="flex items-center justify-between"
                    key={item.titleLicenseId}>
                    <div className="w-1/2 text-slate-800">{index + 1}</div>
                    <div className="w-1/2 text-slate-800">{item.fileName}</div>
                    <div
                      className="w-1/2 text-slate-800 cursor-pointer hover:text-blue-800"
                      onClick={() => window.open(item.filePath)}>
                      View
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <div className="w-1/2 text-slate-800">0</div>
              <div className="w-1/2 text-slate-800">No Data</div>
              <div className="w-1/2 text-slate-800">No Data</div>
            </div>
          )}
        </div>

        <div className="bg-[#38738a] mt-1 px-4 py-2 text-white font-semibold ">
          {"Uploaded Contracts (Addendum)"}
        </div>
        <div className="shadow-lg border-[gray] p-2">
          <div className="flex items-center justify-between">
            <div className="font-bold w-1/2 text-slate-800">Sl No.</div>
            <div className="font-bold w-1/2 text-slate-800">Filename</div>
            <div className="font-bold w-1/2 text-slate-800">Action</div>
          </div>
          {responseData.listofUploadedAddendums !== null ? (
            <div>
              {responseData.listofUploadedAddendums.map((item, index: number) => {
                return (
                  <div
                    className="flex items-center justify-between"
                    key={item.addendumIds}>
                    <div className="w-1/2 text-slate-800">{index + 1}</div>
                    <div className="w-1/2 text-slate-800">{item.fileNames}</div>
                    <div
                      className="w-1/2 text-slate-800 cursor-pointer hover:text-blue-800"
                      onClick={() => window.open(item.filePaths)}>
                      View
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <div className="w-1/2 text-slate-800">0</div>
              <div className="w-1/2 text-slate-800">No Data</div>
              <div className="w-1/2 text-slate-800">No Data</div>
            </div>
          )}
        </div>

        <div className="bg-[#38738a] mt-1 px-4 py-2 text-white font-semibold ">
          {"Royalty Slab"}
        </div>
        <div className="shadow-lg border-[gray] p-2">
          <div className="flex items-center justify-between">
            <div className="font-bold w-1/2 text-slate-800">Sl No.</div>
            <div className="font-bold w-1/2 text-slate-800">Quantity From</div>
            <div className="font-bold w-1/2 text-slate-800">Quantity To</div>
            <div className="font-bold w-1/2 text-slate-800">Royalty Percent</div>
          </div>
          {responseData.listofAddendumRoyaltySlab !== null ? (
            <div>
              {responseData.listofAddendumRoyaltySlab.map((item, index: number) => {
                return (
                  <div
                    className="flex items-center justify-between"
                    key={item.royaltySlabId}>
                    <div className="w-1/2 text-slate-800">{index + 1}</div>
                    <div className="w-1/2 text-slate-800">{item.quantityFrom}</div>
                    <div className="w-1/2 text-slate-800">{item.quantityTo}</div>
                    <div className="w-1/2 text-slate-800">{item.royaltyPercent}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <div className="w-1/2 text-slate-800">0</div>
              <div className="w-1/2 text-slate-800">0</div>
              <div className="w-1/2 text-slate-800">0</div>
              <div className="w-1/2 text-slate-800">0</div>
            </div>
          )}
        </div>
        <div
          className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
          Impressions
        </div>
        {responseData.licenseAddendumListofImpressions !== null ? (
          <div className="py-2">
            <DataTable
              columns={impressionsColumnNames}
              rows={responseData.licenseAddendumListofImpressions}
              sortColumn={false}
              sortingHandling={() => {
                return null;
              }}
              handlePageChange={() => {
                return null;
              }}
              totalRowsCount={0}
              rowsPerPage={0}
              page={0}
              sortColumns={["All"]}
            />
          </div>
        ) : (
          <div className="bg-gray-200 mt-1 px-4 py-2 text-red-500 font-semibold">
            No Impression data available
          </div>
        )}
      </div>
    </div>
  );
};

export default LicenseAddendumDetails;
