import React from "react";

interface DropdownProps {
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  value?: string | number;
  name?: string;
  label?: string;
  errors?: string;
  options?: { id: number | string; name: string }[];
  initialOption?: { id: number | string; name: string };
  width?: string | number;
  height?: string | number;
  disabled?: boolean;
  defaultValue?: number;
  isStringValue?: boolean;
  required?: boolean;
  placeholder?: string;
}

const Dropdown = (props: DropdownProps) => {
  const {
    onChange,
    value,
    name,
    errors,
    label,
    options,
    initialOption,
    width,
    height,
    disabled,
    isStringValue,
    placeholder,
    required,
  } = props;

  return (
    <div className="text-md w-fit mt-2 mr-2 ml-2" data-testid="dropdown-wrapper">
      <div className="text-sm">
        {label && (
          <div className="mb-1" data-testid="dropdown-label">
            {label}
            {required && <span className="ml-1 text-[red]">*</span>}
          </div>
        )}
      </div>
      <select
        data-testid="dropdown-values"
        name={name}
        onChange={onChange}
        value={value}
        className={`${height ?? "h-8"} border ${
          width ?? "min-w-[300px]"
        } rounded-sm outline-none pl-2 text-sm cursor-pointer ${
          disabled && "bg-gray-400 cursor-not-allowed"
        }`}
        disabled={disabled}
        placeholder={placeholder}>
        <option
          value={isStringValue ? initialOption?.name : initialOption?.id}
          disabled>
          {placeholder ?? "Select"}
        </option>
        {options?.map(
          (option: { id: number | string; name: string }, _index: number) => {
            return (
              <option
                key={option.id}
                value={isStringValue ? option.name : option.id}
                className="text-gray-700">
                {option.name}
              </option>
            );
          }
        )}
      </select>
      {errors ? (
        <div className="text-sm text-[red]" data-testid="dropdown-errors">
          {errors}
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
