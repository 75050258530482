import { useEffect, useState } from "react";
import calendar, {
  isDate,
  isSameDay,
  isSameMonth,
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  WEEK_DAYS,
  CALENDAR_MONTHS,
} from "./CalenderHelpers";
import { ClickAwayListener, Menu, MenuItem } from "@mui/material";
import styles from "./Calender.module.css";
import CalenderProps from "./Calender.props";

const Calender = (props: CalenderProps) => {
  const { date, onDateChanged, isCalenderOpen, handleClickAway } = props;
  const [dateState, setDateState] = useState<{
    current: number | Date | null;
    month: number;
    year: number;
  }>({ current: 0, month: 0, year: 0 });
  const [today] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    addDateToState(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalenderOpen]);

  const handleCalenderYearClick = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    if ((event as React.KeyboardEvent<HTMLElement>).key !== "Tab") {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleCalenderYearDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleCalenderYear = (year: number) => {
    setDateState({
      month: dateState.month,
      year: year,
      current: dateState.current,
    });
    setAnchorEl(null);
  };

  const addDateToState = (date: Date) => {
    const isDateObject = isDate(date);
    const _date = isDateObject ? date : new Date();
    setDateState({
      current: isDateObject ? date : null,
      month: +_date.getMonth() + 1,
      year: _date.getFullYear(),
    });
  };

  const getCalendarDates = () => {
    const { month, year } = dateState;
    const calendarMonth = month || date.getMonth() + 1;
    const calendarYear = year || date.getFullYear();
    return calendar(calendarMonth, calendarYear);
  };

  const renderMonthAndYear = () => {
    const { month, year } = dateState;
    // Resolve the month name from the CALENDAR_MONTHS object map
    const monthname =
      Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))];
    return (
      <div className="calender-header">
        <div className="border-b-2 font-bold p-2 text-sm flex justify-between border-[#1f1463]">
          <div
            onClick={gotoPreviousMonth}
            onKeyDown={gotoPreviousMonth}
            tabIndex={0}
            role="button"
            className="text-[#1f1463]">
            <i className="fa-solid fa-chevron-left"></i>
          </div>
          <div>
            <div
              onClick={handleCalenderYearClick}
              onKeyDown={handleCalenderYearClick}
              tabIndex={0}
              role="button"
              className="text-xs text-[#1f1463]">
              {monthname} {year}
              <i className="fa-solid fa-chevron-down ml-2"></i>
            </div>
            <div
              onClick={handleCalenderYearClick}
              onKeyDown={handleCalenderYearClick}
              tabIndex={0}
              role="button"
              className="text-xs"></div>
            <Menu
              id="calender-year-dropdown"
              aria-labelledby="calender-year-dropdown-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCalenderYearDropdownClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  maxHeight: 300,
                },
              }}>
              {renderYears()}
            </Menu>
          </div>
          <div
            onClick={gotoNextMonth}
            onKeyDown={gotoNextMonth}
            tabIndex={0}
            role="button"
            className="text-[#1f1463]">
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
    );
  };

  const renderYears = () => {
    return Array.from({ length: 100 }, (_value, index) => 1950 + index).map(
      (data) => (
        <MenuItem onClick={() => handleCalenderYear(data)} key={data}>
          {data}
        </MenuItem>
      )
    );
  };

  const renderDayLabel = (day: string, index: number) => {
    // Resolve the day of the week label from the WEEK_DAYS object map
    const daylabel = WEEK_DAYS[day as keyof typeof WEEK_DAYS].toUpperCase();
    return (
      <div
        key={daylabel}
        className={`${
          styles.calenderfs
        } text-[#1f1463] flex flex-1 justify-center items-center p-1 ${
          index < 6 ? "border-r-2" : null
        } border-[#1f1463] h-7 w-9`}>
        {daylabel}
      </div>
    );
  };

  const gotoDate =
    (date: Date) =>
    (evt: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
      if ((evt as React.KeyboardEvent<HTMLElement>).key !== "Tab") {
        evt && evt.preventDefault();
        const { current } = dateState;
        !(current && isSameDay(date, current as unknown as Date)) &&
          addDateToState(date);
        onDateChanged(date);
      }
    };

  const renderCalendarDate = (date: Array<string | number>, index: number) => {
    const { current, month, year } = dateState;
    const _date = new Date(date.join("-"));
    // Check if calendar date is same day as today
    const isToday = isSameDay(_date, today);
    // Check if calendar date is same day as currently selected date
    const isCurrent = current && isSameDay(_date, current as unknown as Date);
    // Check if calendar date is in the same month as the state month and year
    const inmonth =
      month && year && isSameMonth(_date, new Date([year, month, 1].join("-")));
    // The click handler
    const onClick = gotoDate(_date);
    const onKeyDown = gotoDate(_date);
    const props = {
      index,
      onClick,
      onKeyDown,
      title: _date.toDateString(),
    };

    return (
      <div
        key={getDateISO(_date)}
        {...props}
        tabIndex={0}
        className={`cursor-pointer text-xs ${
          isToday && inmonth ? "bg-[#1f1463] text-white" : ""
        } ${
          isCurrent ? "border-[#1f1463]" : "border-r-0 border-b-0"
        } flex justify-center items-center p-1 w-9 border ${
          inmonth ? "" : "text-gray-300"
        }`}>
        {_date.getDate()}
      </div>
    );
  };

  const gotoPreviousMonth = (
    evt: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
  ) => {
    const { month, year } = dateState;
    if ((evt as React.KeyboardEvent<HTMLElement>).key !== "Tab") {
      const previousMonth = getPreviousMonth(month, year);
      setDateState({
        month: previousMonth.month,
        year: previousMonth.year,
        current: dateState.current,
      });
    }
  };
  const gotoNextMonth = (
    evt: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
  ) => {
    const { month, year } = dateState;
    if ((evt as React.KeyboardEvent<HTMLElement>).key !== "Tab") {
      const nextMonth = getNextMonth(month, year);
      setDateState({
        month: nextMonth.month,
        year: nextMonth.year,
        current: dateState.current,
      });
    }
  };

  const handleClickAwayCalender = () => {
    handleClickAway();
  };

  return (
    <div className="calender-wrapper">
      {isCalenderOpen && (
        <ClickAwayListener onClickAway={handleClickAwayCalender}>
          <div
            className={`z-10 border-2 border-[#1f1463] w-64 h-max bg-white absolute left-2 bottom-0 top-10 ${styles.calenderContainer}`}>
            {renderMonthAndYear()}
            <div className="flex justify-start font-bold text-xs border-b-2 items-start border-[#1f1463]">
              {Object.keys(WEEK_DAYS).map(renderDayLabel)}
            </div>
            <div className="flex flex-wrap">
              {getCalendarDates().map(renderCalendarDate)}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Calender;
