import * as React from "react";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface dialogProps {
  open: boolean;
  handleClose: () => void;
  children: JSX.Element;
}

export default function AlertDialog(props: dialogProps) {
  const { open, handleClose, children } = props;

  return (
    <div data-testid="dialog-wrapper">
      <Dialog
        data-testid="dialog-content"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        {children}
      </Dialog>
    </div>
  );
}
