const sliceTime = (value: string) => {
  let date;
  if (value === null || value === "") {
    return value;
  } else {
    date = value.slice(0, 10);
    return date;
  }
};

export default sliceTime;
