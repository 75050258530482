import { useDispatch, useSelector } from "react-redux";
import Calender from "../../../components/Calender/Calender";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Input from "../../../components/Input/Input";
import SubHeading from "../../../components/SubHeading/SubHeading";
import TextArea from "../../../components/TextArea/TextArea";
import { colors } from "../../../config/colors/colors";
import ReduxStateProps from "../../../redux/Redux.props";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { updateBreadcrumb, updateIsLoading } from "../../../redux/action";
import axiosInstance from "../../../service/axiosInstane";
import { useNavigate, useParams } from "react-router-dom";
import CheckBox from "../../../components/CheckBox/CheckBox";
import RadioButton from "../../../components/RadioButton/RadioButton";
import Button from "../../../components/Button/Button";
import sliceTime from "../../../helpers/sliceTime";
import DataTable from "../../../components/DataTable/DataTable";
import AlertDialog from "../../../components/Dialog/Dialog";
import { FaTrashAlt } from "react-icons/fa";
import { snackBarColors } from "../../../config/constants";
import SnackBar from "../../../components/SnackBar/SnackBar";
import Select from "../../../components/Select/Select";

type DownloadDocProps = {
  documentId: string;
  assetIds: string | null;
  documentName: string | null;
  documentType: string;
  documentFileName: string;
  documentPath: string;
  documentDescription: null;
  createdById: number;
  createdBy: string;
  updatedById: number | null;
  updatedBy: string;
  createdOn: string;
  updatedOn: string;
  files: string[];
};

type ProjectTeamProps = {
  userId: number;
  firstName: string;
  lastName: string;
  userEmail: string;
};

type ProjectTitlesRequestProps = {
  strSortField: string;
  strSortOrder: string;
  filterChar: string;
  searchStr: string;
  pageNo: number;
  pageSize: number;
  projectId?: string;
};

type ProjectTitleProps = {
  titleId: string;
  titleCode: string;
  titleName: string;
  authors: string;
  authorContractNumber: string;
  pubDate: string;
  isPubDateTentative: boolean;
  isPubDateActual: boolean;
  editionNumber: string;
  contentType: string;
  identifierType: string;
  identifiers: string;
  parentIdentifierType: string;
  parentIdentifier: string;
  pubStatus: string | null;
  TotalCount: number;
};

type ProjectDocProps = {
  documentId: number;
  assetIds: string;
  documentName: string | null;
  documentType: string | null;
  documentFileName: string;
  documentPath: string;
  documentDescription: string | null;
  updatedBy: string | null;
  createdBy: string;
};

type FormikValidateProps = {
  projectName: string;
  businessUnit: string;
  legalEntity: string;
  projectStatus: string;
  projectStart: string;
  paymentCurrency: string;
  permissionsStrategy: string;
  supportingSupplements: string;
  foreignLanguageTranslations: string;
  derivatives: string;
  reuseOutsideofOriginalProgram: string;
  permittedUsers: string;
  reuseofParts: string;
  outofContextReuseAllowed: string;
};

const userColumns = [
  {
    id: "firstName",
    label: "First Name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "lastName",
    label: "Last Name",
    minWidth: 70,
    align: "left",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 70,
    align: "left",
  },
];

const titleDetailsColumns = [
  {
    id: "titleName",
    label: "Title",
    minWidth: 100,
    align: "left",
  },
  {
    id: "authors",
    label: "Author(s)",
    minWidth: 70,
    align: "left",
  },
  {
    id: "contentType",
    label: "Content Type",
    minWidth: 70,
    align: "left",
  },
  {
    id: "identifiers",
    label: "Identifier",
    minWidth: 70,
    align: "left",
  },
  {
    id: "pubDate",
    label: "Pub Date",
    minWidth: 70,
    align: "left",
  },
  {
    id: "pubStatus",
    label: "Pub Status",
    minWidth: 70,
    align: "left",
  },
  {
    id: "editionNumber",
    label: "Ed.",
    minWidth: 70,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 70,
    align: "left",
  },
];

const documentColumns = [
  {
    id: "documentFileName",
    label: "File Name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "documentDescription",
    label: "Description",
    minWidth: 70,
    align: "left",
  },
  {
    id: "documentType",
    label: "Document Type",
    minWidth: 70,
    align: "left",
  },
  {
    id: "createdBy",
    label: "Created By",
    minWidth: 70,
    align: "left",
  },
  {
    id: "updatedBy",
    label: "Last Updated By",
    minWidth: 70,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 70,
    align: "left",
  },
];

const UpdateProject = () => {
  const { projectId } = useParams<string>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUserId = useSelector((state: ReduxStateProps) => state.user.id);
  const dropdownList = useSelector(
    (state: ReduxStateProps) => state.projectDropdownList
  );
  const [isStartCalenderOpen, setIsStartCalenderOpen] = useState(false);
  const [isEndCalenderOpen, setIsEndCalenderOpen] = useState(false);
  const [isExpectedCalenderOpen, setIsExpectedCalenderOpen] = useState(false);
  const [titleRowsPerPage, setTitleRowsPerPage] = useState(20);
  const [assetRowsPerPage, setAssetRowsPerPage] = useState(20);
  const [titlePage, setTitlePage] = useState(0);
  const [assetPage, setAssetPage] = useState(0);
  const [totalTitleRowsCount, setTotalTitleRowsCount] = useState(0);
  const [totalAssetRowsCount, setTotalAssetRowsCount] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [teamMemberDetails, setTeamMemberDetails] = useState<ProjectTeamProps[]>([]);
  const [snackBarOpen, setSnackBarOpen] = useState({
    value: false,
    message: "",
    type: "",
  });
  const [isUserData, setIsUserData] = useState(false);
  const [fName, setFName] = useState("");
  const [userDetails, setUserDetails] = useState([
    {
      userId: 0,
      firstName: "",
      lastName: "",
      userEmail: "",
    },
  ]);
  const [projectMember, setProjectMember] = useState<Array<string>>([]);
  const [isPubDateCalenderOpen, setIsPubDateCalenderOpen] = useState(false);
  const [openAddRelatedTitle, setOpenAddRelatedTitle] = useState(false);
  const [titleCodeIsbn, setTitleCodeIsbn] = useState("");
  const [projectTitles, setProjectTitles] = useState<ProjectTitleProps[]>([]);
  const [projectDocs, setProjectDocs] = useState<ProjectDocProps[]>([]);
  const [authorsMenuList, setAuthorsMenuList] = useState<
    Array<{ id: number; name: string }>
  >([]);
  const [editAuthorsMenuList, setEditAuthorsMenuList] = useState<
    Array<{ id: number; name: string }>
  >([]);
  const [newTitle, setNewTitle] = useState("");
  const [updateDoc, setUpdateDoc] = useState("");
  const [docId, setDocId] = useState<string | number>();
  const [titleId, setTitleId] = useState<string | number>();
  const [noProjectTitle, setNoProjectTitle] = useState<string>();
  const [noProjectDoc, setNoProjectDoc] = useState<string>();
  const [projectTeamArrayList, setProjectTeamArrayList] = useState<
    {
      userId: number;
      firstName: string;
      lastName: string;
      userEmail: string;
    }[]
  >([]);
  const projectTitlesRequest = {
    strSortField: "id",
    strSortOrder: "ASC",
    filterChar: "",
    searchStr: "",
    pageNo: 1,
    pageSize: 20,
    projectId: projectId,
  };

  useEffect(() => {
    const crumbs = [
      {
        name: "Projects",
      },
      {
        path: "/title/projects_home",
        name: "My Projects",
      },
      {
        path: "/title/update_project_details",
        name: "Update Project",
      },
    ];
    dispatch(updateBreadcrumb(crumbs));
  }, []);

  const initialValues = {
    projectId: 0,
    projectCode: "",
    projectName: "",
    businessUnit: "",
    legalEntity: "",
    projectStatus: "",
    projectStart: null,
    projectEnd: null,
    expectedPaymentDate: null,
    paymentCurrency: "",
    generalComments: "",
    permissionsStrategy: "",
    projectTeam: [
      {
        userId: 0,
        firstName: "",
        lastName: "",
        userEmail: "",
      },
    ],
    supportingSupplements: null,
    foreignLanguageTranslations: null,
    promotionalUse: "",
    numberofEditions: "",
    derivatives: null,
    abridgements: true,
    briefEditions: true,
    splitEditions: true,
    adaptations: true,
    customVersions: true,
    otherVersions: true,
    reuseOutsideofOriginalProgram: null,
    permittedUsers: "",
    reuseofParts: null,
    chapterOrUnit: true,
    portionsOfChapterOrUnit: true,
    outofContextReuseAllowed: null,
    allMediaDistribution: false,
    printDistribution: false,
    digitalDistribution: false,
    distributionOthers: "",
    territoriesWorldRights: false,
    territoriesWorldRightsExclNorthamerica: false,
    territoriesNonWorldRights: false,
    territoriesOthers: "",
    languagesAll: false,
    languagesOthers: "",
    updatedBy: 0,
    updatedDate: "",
    existingTitleCodeOrLbplIsbn: "",
    titleCode: "",
    titleId: 0,
    title: "",
    authors: [0],
    newTitleAuthors: "",
    authorData: null,
    authorContractNumber: "",
    pubDate: "",
    isPubDateTentative: false,
    isPubDateActual: false,
    editionNumber: "",
    contentType: "",
    identifierType: "",
    identifier: "",
    parentIdentifierType: "",
    parentIdentifier: "",
    createdBy: loggedInUserId,
    createdDate: "",
    documentType: "",
    documentFileName: "",
    documentDescription: "",
    documentPath: "",
    docfile: null,
    projectTitle: null,
  };

  const onSubmit = (values: any) => {
    const validationErrors = validate(values);
    if (Object.keys(validationErrors).length === 0) {
      updateProjectDetails();
    } else {
      formik.setErrors(validationErrors);
      setSnackBarOpen({
        ...snackBarOpen,
        value: true,
        message: "Please fill all the required fields",
        type: snackBarColors.error,
      });
    }
  };

  const updateProjectDetails = () => {
    const updatePayload = {
      projectId: projectId,
      projectCode: formik.values.projectCode,
      projectName: formik.values.projectName.trimEnd(),
      businessUnit: formik.values.businessUnit,
      legalEntity: formik.values.legalEntity,
      projectStatus: formik.values.projectStatus,
      projectStart: formik.values.projectStart,
      projectEnd: formik.values.projectEnd,
      expectedPaymentDate: formik.values.expectedPaymentDate,
      paymentCurrency: formik.values.paymentCurrency,
      generalComments: formik.values.generalComments,
      permissionsStrategy: formik.values.permissionsStrategy,
      projectTeam: formik.values.projectTeam,
      supportingSupplements: formik.values.supportingSupplements,
      foreignLanguageTranslations: formik.values.foreignLanguageTranslations,
      promotionalUse: formik.values.promotionalUse,
      numberofEditions: formik.values.numberofEditions,
      derivatives: formik.values.derivatives,
      abridgements: formik.values.abridgements,
      briefEditions: formik.values.briefEditions,
      splitEditions: formik.values.splitEditions,
      adaptations: formik.values.adaptations,
      customVersions: formik.values.customVersions,
      otherVersions: formik.values.otherVersions,
      reuseOutsideofOriginalProgram: formik.values.reuseOutsideofOriginalProgram,
      permittedUsers: formik.values.permittedUsers,
      reuseofParts: formik.values.reuseofParts,
      chapterOrUnit: formik.values.chapterOrUnit,
      portionsOfChapterOrUnit: formik.values.portionsOfChapterOrUnit,
      outofContextReuseAllowed: formik.values.outofContextReuseAllowed,
      allMediaDistribution: formik.values.allMediaDistribution,
      printDistribution: formik.values.printDistribution,
      digitalDistribution: formik.values.digitalDistribution,
      distributionOthers: formik.values.distributionOthers,
      territoriesWorldRights: formik.values.territoriesWorldRights,
      territoriesWorldRightsExclNorthamerica:
        formik.values.territoriesWorldRightsExclNorthamerica,
      territoriesNonWorldRights: formik.values.territoriesNonWorldRights,
      territoriesOthers: formik.values.territoriesOthers,
      languagesAll: formik.values.languagesAll,
      languagesOthers: formik.values.languagesOthers,
      updatedBy: loggedInUserId,
      updatedDate: "2023-09-20T10:19:56.468Z",
    };
    dispatch(updateIsLoading(true));
    axiosInstance
      .put(`/projects/${projectId}`, updatePayload)
      .then((response) => {
        if (response.status === 200) {
          setOpen(false);
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: response.data.message,
            type: snackBarColors.success,
          });
        }
        navigate("/title/projects_home");
        dispatch(updateIsLoading(false));
      })
      .catch((_err) => {
        setOpen(false);
        setSnackBarOpen({
          ...snackBarOpen,
          value: true,
          message: "Please fill all the required fields",
          type: snackBarColors.error,
        });
        dispatch(updateIsLoading(false));
      });
  };

  const validate = (values: FormikValidateProps) => {
    const errors: any = {};
    if (values.projectName === "") {
      errors.projectName = "Required";
    }
    if (!values.businessUnit) {
      errors.businessUnit = "Required";
    }
    if (!values.legalEntity) {
      errors.legalEntity = "Required";
    }
    if (!values.projectStatus) {
      errors.projectStatus = "Required";
    }
    if (!values.projectStart) {
      errors.projectStart = "Required";
    }
    if (!values.paymentCurrency) {
      errors.paymentCurrency = "Required";
    }
    if (!values.permissionsStrategy) {
      errors.permissionsStrategy = "Required";
    }
    if (values.supportingSupplements === null) {
      errors.supportingSupplements = "Required";
    }
    if (values.foreignLanguageTranslations === null) {
      errors.foreignLanguageTranslations = "Required";
    }
    if (values.derivatives === null) {
      errors.derivatives = "Required";
    }
    if (values.reuseOutsideofOriginalProgram === null) {
      errors.reuseOutsideofOriginalProgram = "Required";
    }
    if (!values.permittedUsers) {
      errors.permittedUsers = "Required";
    }
    if (values.reuseofParts === null) {
      errors.reuseofParts = "Required";
    }
    if (values.outofContextReuseAllowed === null) {
      errors.outofContextReuseAllowed = "Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validateOnChange: false,
  });

  const formatDate = (date: string) => {
    let newDate = new Date(date),
      month = "" + (newDate.getMonth() + 1),
      day = "" + newDate.getDate();
    const year = newDate.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    getProjectDetails();
    getProjectTitleDetails(projectTitlesRequest);
    getProjectDocumentsDetails();
  }, []);

  const getProjectDetails = () => {
    dispatch(updateIsLoading(true));
    axiosInstance
      .get(`/projects/${projectId}`)
      .then((response) => {
        if (response.status === 200) {
          formik.setValues(response.data);

          if (response.data.expectedPaymentDate !== null) {
            formik.setFieldValue(
              "expectedPaymentDate",
              formatDate(response.data.expectedPaymentDate)
            );
          }
          formik.setFieldValue(
            "projectStart",
            formatDate(response.data.projectStart)
          );
          if (response.data.projectEnd !== null) {
            formik.setFieldValue("projectEnd", formatDate(response.data.projectEnd));
          }
          formik.setFieldValue(
            "paymentCurrency",
            dropdownList?.paymentCurrency
              .filter((item) => item.name === response.data.paymentCurrency)[0]
              ?.id.toString()
          );
          formik.setFieldValue("projectTeam", [...response.data.projectTeam]);
          setTeamMemberDetails(response.data.projectTeam);
          setProjectMember(
            response.data.projectTeam.map((team: ProjectTeamProps) => team.firstName)
          );
        }
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        dispatch(updateIsLoading(false));
      });
  };

  const getProjectTitleDetails = (requesObj: ProjectTitlesRequestProps) => {
    dispatch(updateIsLoading(true));
    axiosInstance
      .post(`/projects/${projectId}/titles`, requesObj)
      .then((response) => {
        if (response.status === 200) {
          setNoProjectTitle("");
          setProjectTitles(response?.data);
        } else {
          setNoProjectTitle("No data found");
        }
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        setNoProjectTitle("No data found");
        dispatch(updateIsLoading(false));
      });
  };

  const getProjectDocumentsDetails = async () => {
    dispatch(updateIsLoading(true));
    await axiosInstance
      .get(`/projects/getdocumentslist/${projectId}`)
      .then((response) => {
        if (response.status === 200) {
          setProjectDocs(response.data);
          setNoProjectDoc("");
        } else {
          setNoProjectDoc("No data found");
        }
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        setNoProjectDoc("No data found");
        dispatch(updateIsLoading(false));
      });
  };

  const handleRadioClick = (name: string, value: boolean) => {
    formik.setFieldValue(name, value);
  };

  const handleTitleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTitleRowsPerPage(+event.target.value);
    setTitlePage(0);
  };

  const handleTitlePageChange = (_event: unknown, newPage: number) => {
    setTitlePage(newPage);
  };

  const handleTerritoriesRadioClick = (name: string, value: boolean) => {
    formik.setFieldValue(name, value);
    if (name === "territoriesWorldRights") {
      formik.setFieldValue("territoriesWorldRights", true);
      formik.setFieldValue("territoriesWorldRightsExclNorthamerica", false);
      formik.setFieldValue("territoriesNonWorldRights", false);
    } else if (name === "territoriesWorldRightsExclNorthamerica") {
      formik.setFieldValue("territoriesWorldRightsExclNorthamerica", true);
      formik.setFieldValue("territoriesWorldRights", false);
      formik.setFieldValue("territoriesNonWorldRights", false);
    } else if (name === "territoriesNonWorldRights") {
      formik.setFieldValue("territoriesNonWorldRights", true);
      formik.setFieldValue("territoriesWorldRights", false);
      formik.setFieldValue("territoriesWorldRightsExclNorthamerica", false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    const files = e.target.files;
    formik.setFieldValue("docfile", e.currentTarget.files?.[0]);
    if (files && files.length > 0) {
      const file = files?.[0];
      formik.setFieldValue("documentFileName", file.name);
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDocSave = () => {
    if (formik.values.documentFileName === "") {
      setSnackBarOpen({
        ...snackBarOpen,
        value: true,
        message: "Please fill the required fields",
        type: snackBarColors.error,
      });
    } else {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      if (updateDoc === "Create") {
        const createPayload = {
          DocumentId: "",
          ProjectId: projectId,
          DocumentName: "",
          DocumentType: formik.values.documentType,
          DocumentFileName: formik.values.documentFileName,
          DocumentPath: "",
          DocumentDescription: formik.values.documentDescription,
          CreatedBy: loggedInUserId,
          UpdatedBy: loggedInUserId,
          Files: formik.values.docfile,
        };
        dispatch(updateIsLoading(true));
        axiosInstance
          .post(`/projects/${projectId}/documents`, createPayload, config)
          .then((response) => {
            if (response.status === 200) {
              setOpen(false);
              setSnackBarOpen({
                ...snackBarOpen,
                value: true,
                message: response.data.message,
                type: snackBarColors.success,
              });
            }
            getProjectDocumentsDetails();
            dispatch(updateIsLoading(false));
          })
          .catch((err) => {
            setOpen(false);
            setSnackBarOpen({
              ...snackBarOpen,
              value: true,
              message: err.response.data.message,
              type: snackBarColors.error,
            });
            dispatch(updateIsLoading(false));
          });
      } else {
        const updatePayload = {
          projectId: projectId,
          documentId: Number(docId),
          documentName: "",
          documentType: formik.values.documentType,
          documentFileName: formik.values.documentFileName,
          documentPath: formik.values.documentPath,
          documentDescription: formik.values.documentDescription,
          updatedBy: loggedInUserId,
        };
        dispatch(updateIsLoading(true));
        axiosInstance
          .put(`/projects/${projectId}/updatedocument`, updatePayload)
          .then((response) => {
            if (response.status === 200) {
              setOpen(false);
              setSnackBarOpen({
                ...snackBarOpen,
                value: true,
                message: response.data.message,
                type: snackBarColors.success,
              });
            }
            getProjectDocumentsDetails();
            dispatch(updateIsLoading(false));
          })
          .catch((err) => {
            setOpen(false);
            setSnackBarOpen({
              ...snackBarOpen,
              value: true,
              message: err.response.data.message,
              type: snackBarColors.error,
            });
            dispatch(updateIsLoading(false));
          });
      }
    }
  };

  const attachDocDialog = () => {
    return (
      <div className="p-5">
        <div className="flex justify-center font-bold text-xl">{`${
          updateDoc === "Create" ? "Attach Document" : "Update Document"
        }`}</div>
        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between w-[450px]">
                <div className="font-semibold">
                  File Name<span className="text-[red]">*</span>
                </div>
                <Input
                  type={"text"}
                  name="documentFileName"
                  disabled={true}
                  value={formik.values.documentFileName}
                  errors={formik.errors.documentFileName}
                  onChange={formik.handleChange}
                />
                <input
                  ref={inputRef}
                  className="hidden"
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFileChange(e)
                  }
                />
              </div>
              {updateDoc === "Create" && (
                <div className="mt-2">
                  <Button
                    title={"Browse"}
                    type={"button"}
                    color={"normal"}
                    height="h-[30px]"
                    onClick={() => handleClick()}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center justify-between w-[450px]">
              <div className="font-semibold">{"Document Type"}</div>
              <Dropdown
                name="documentType"
                isStringValue={true}
                width="w-[300px]"
                options={dropdownList.projectDocumentType}
                value={formik.values.documentType}
                onChange={formik.handleChange}
                initialOption={{
                  id: "",
                  name: "",
                }}
                placeholder="Select"
              />
            </div>
            <div className="flex items-center justify-between w-[450px]">
              <div className="font-semibold">{"Document Description"}</div>
              <TextArea
                name="documentDescription"
                value={formik.values.documentDescription}
                onChange={formik.handleChange}
              />
            </div>
            <div className="flex justify-center space-x-2">
              <Button
                title={`${updateDoc === "Create" ? "Save" : "Update"}`}
                type={"button"}
                color={"primary"}
                onClick={() => onDocSave()}
              />
              <Button
                title={"Cancel"}
                type={"button"}
                color={"danger"}
                onClick={() => handleClose()}
              />
            </div>
          </form>
        </div>
      </div>
    );
  };

  const onUserSearch = () => {
    dispatch(updateIsLoading(false));
    axiosInstance
      .get(`/users/${fName}`)
      .then((response) => {
        if (response.status === 200) {
          setIsUserData(true);
          const userDetailsArray = response?.data.map(
            (item: {
              userId: number;
              firstName: string;
              lastName: string;
              userEmail: string;
            }) => ({
              userId: item?.userId,
              firstName: item?.firstName,
              lastName: item?.lastName,
              userEmail: item?.userEmail,
              isChecked:
                projectMember.length > 0
                  ? projectMember.includes(item.firstName)
                  : false,
            })
          );
          setUserDetails([...userDetailsArray]);
        } else {
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: "No Team Members Found",
            type: snackBarColors.error,
          });
        }
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        setIsUserData(false);
        dispatch(updateIsLoading(false));
        setSnackBarOpen({
          ...snackBarOpen,
          value: true,
          message: err.response.data.message,
          type: snackBarColors.error,
        });
      });
  };

  const relatedTitle = () => {
    setNewTitle("");
    dispatch(updateIsLoading(true));
    axiosInstance
      .get(`/titles/${titleCodeIsbn}`)
      .then((response) => {
        if (response?.status === 200) {
          setOpenAddRelatedTitle(true);
          const authorsList = response?.data?.authors?.map(
            (item: {
              id: number;
              main: boolean;
              surName: string;
              firstName: string;
            }) => ({
              id: item?.id,
              name: `${item?.firstName} ${item?.surName}`,
            })
          );
          formik.setFieldValue("titleCode", response?.data?.titleCode);
          formik.setFieldValue("title", response?.data?.title);
          formik.setFieldValue("identifier", response?.data?.identifier);
          formik.setFieldValue("parentIdentifier", response?.data?.parentIdentifier);
          formik.setFieldValue("identifierType", "");
          formik.setFieldValue("parentIdentifierType", "");
          formik.setFieldValue("contentType", "");
          formik.setFieldValue(
            "authors",
            response?.data?.authors?.map(
              (item: {
                id: number;
                main: boolean;
                surName: string;
                firstName: string;
              }) => item.id
            )
          );
          setAuthorsMenuList([...authorsList]);
        } else {
          setOpenAddRelatedTitle(false);
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: "No Title Date",
            type: snackBarColors.error,
          });
        }
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        setOpenAddRelatedTitle(false);
        setSnackBarOpen({
          ...snackBarOpen,
          value: true,
          message: err.response.data.message,
          type: snackBarColors.error,
        });
      });
    dispatch(updateIsLoading(false));
  };

  const addNewTitle = () => {
    setTitleCodeIsbn("");
    setNewTitle("newtitle");
    setOpenAddRelatedTitle(true);
    formik.setFieldValue("titleCode", "");
    formik.setFieldValue("title", "");
    formik.setFieldValue("identifier", "");
    formik.setFieldValue("parentIdentifier", "");
    formik.setFieldValue("identifierType", "");
    formik.setFieldValue("parentIdentifierType", "");
    formik.setFieldValue("pubDate", "");
    formik.setFieldValue("contentType", "");
    formik.setFieldValue("isPubDateTentative", false);
    formik.setFieldValue("isPubDateActual", false);
    formik.setFieldValue("editionNumber", "");
    formik.setFieldValue("authors", "");
    formik.setFieldValue("authorContractNumber", "");
  };

  const addRelatedTitle = () => {
    if (
      formik.values.title === "" ||
      formik.values.identifier === "" ||
      formik.values.identifierType === "" ||
      formik.values.contentType === "" ||
      formik.values.pubDate === ""
    ) {
      setSnackBarOpen({
        ...snackBarOpen,
        value: true,
        message: "Please fill all the required fields",
        type: snackBarColors.error,
      });
    } else {
      addProjectTitle();
    }
  };

  const addProjectTitle = () => {
    if (newTitle === "edit") {
      const titleUpdatePayload = {
        projectId: projectId,
        titleId: titleId,
        title: formik.values.title,
        authors: formik.values.authors,
        authorContractNumber: formik.values.authorContractNumber,
        pubDate: formik.values.pubDate,
        isPubDateTentative: formik.values.isPubDateTentative,
        isPubDateActual: formik.values.isPubDateActual,
        editionNumber: formik.values.editionNumber,
        contentType: formik.values.contentType,
        identifierType: formik.values.identifierType,
        identifier: formik.values.identifier,
        parentIdentifierType: formik.values.parentIdentifierType,
        parentIdentifier: formik.values.parentIdentifier,
        updatedBy: loggedInUserId,
      };
      setOpenAddRelatedTitle(false);
      dispatch(updateIsLoading(true));
      axiosInstance
        .put("/projecttitle", titleUpdatePayload)
        .then((response) => {
          if (response.status === 200) {
            setSnackBarOpen({
              ...snackBarOpen,
              value: true,
              message: response.data.message,
              type: snackBarColors.success,
            });
          }
          getProjectTitleDetails(projectTitlesRequest);
        })
        .catch((err) => {
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: err.response.data.message,
            type: snackBarColors.error,
          });
          dispatch(updateIsLoading(false));
        });
      dispatch(updateIsLoading(false));
    } else {
      const titleCreatePayload = {
        projectId: projectId,
        existingTitleCodeOrLbplIsbn: `${
          newTitle === "newtitle" ? "" : titleCodeIsbn
        }`,
        titleCode: formik.values.titleCode,
        titleId: 0,
        title: formik.values.title,
        authors: newTitle === "newtitle" ? null : formik.values.authors,
        newTitleAuthors: formik.values.newTitleAuthors,
        authorData: null,
        authorContractNumber: formik.values.authorContractNumber,
        pubDate: formik.values.pubDate,
        isPubDateTentative: formik.values.isPubDateTentative,
        isPubDateActual: formik.values.isPubDateActual,
        editionNumber: formik.values.editionNumber,
        contentType: formik.values.contentType,
        identifierType: formik.values.identifierType,
        identifier: formik.values.identifier,
        parentIdentifierType: formik.values.parentIdentifierType,
        parentIdentifier: formik.values.parentIdentifier,
        createdBy: 0,
        createdDate: "",
      };
      setOpenAddRelatedTitle(false);
      dispatch(updateIsLoading(true));
      axiosInstance
        .post("/projecttitle", titleCreatePayload)
        .then((response) => {
          if (response.status === 200) {
            setSnackBarOpen({
              ...snackBarOpen,
              value: true,
              message: response.data.message,
              type: snackBarColors.success,
            });
          }
          getProjectTitleDetails(projectTitlesRequest);
        })
        .catch((err) => {
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: err.response.data.message,
            type: snackBarColors.error,
          });
          dispatch(updateIsLoading(false));
        });
      dispatch(updateIsLoading(false));
    }
  };

  const deleteProjectTitle = (arg: string | number, index: number) => {
    const obj: { titleIds: number[]; deletedBy: number } = {
      titleIds: [Number(arg)],
      deletedBy: loggedInUserId,
    };
    axiosInstance
      .delete(`/projects/${projectId}/title`, { data: obj })
      .then((response) => {
        if (response.status === 200) {
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: response.data.message,
            type: snackBarColors.success,
          });
        }
        getProjectTitleDetails(projectTitlesRequest);
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        setSnackBarOpen({
          ...snackBarOpen,
          value: true,
          message: err.response.data.message,
          type: snackBarColors.error,
        });
        dispatch(updateIsLoading(false));
      });
  };

  const onTitleEdit = (arg: string | number, index: number) => {
    setTitleId(arg);
    setNewTitle("edit");
    dispatch(updateIsLoading(true));
    axiosInstance
      .get(`/projects/${projectId}/titles/${arg}`)
      .then((response) => {
        if (response.status === 200) {
          setOpenAddRelatedTitle(true);
          const titleAuthorsList = response?.data?.authorData?.map(
            (item: {
              authorId: number;
              authorName: string;
              authorContractNumber: string;
            }) => ({
              id: item?.authorId,
              name: `${item?.authorName}`,
            })
          );

          formik.setFieldValue("title", response?.data?.title);
          formik.setFieldValue("titleCode", response?.data?.titleCode);
          formik.setFieldValue(
            "authorContractNumber",
            response?.data?.authorContractNumber
          );
          formik.setFieldValue("pubDate", sliceTime(response?.data?.pubDate));
          formik.setFieldValue("editionNumber", response?.data?.editionNumber);
          formik.setFieldValue("contentType", response?.data?.contentType);
          formik.setFieldValue("identifierType", response?.data?.identifierType);
          formik.setFieldValue("identifier", response?.data?.identifier);
          formik.setFieldValue(
            "parentIdentifierType",
            response?.data?.parentIdentifierType
          );
          formik.setFieldValue("parentIdentifier", response?.data?.parentIdentifier);
          formik.setFieldValue(
            "isPubDateTentative",
            response?.data?.isPubDateTentative
          );
          formik.setFieldValue("isPubDateActual", response?.data?.isPubDateActual);
          formik.setFieldValue(
            "authors",
            response?.data?.authorData?.map(
              (item: {
                authorId: number;
                authorName: string;
                authorContractNumber: string;
              }) => item.authorId
            )
          );
          setEditAuthorsMenuList([...titleAuthorsList]);
        }
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        setSnackBarOpen({
          ...snackBarOpen,
          value: true,
          message: err.response.data.message,
          type: snackBarColors.error,
        });
        dispatch(updateIsLoading(false));
      });
  };

  const onDocEdit = (arg: string | number, index: number) => {
    setDocId(arg);
    setUpdateDoc("Edit");
    const obj = {
      projectId: projectId,
      assetId: 0,
      documentId: Number(arg),
    };
    setOpen(true);
    dispatch(updateIsLoading(true));
    axiosInstance
      .post("/projects/getdocuments", obj)
      .then((response) => {
        if (response.status === 200) {
          formik.setFieldValue("documentFileName", response.data.documentFileName);
          formik.setFieldValue("documentType", response.data.documentType);
          formik.setFieldValue(
            "documentDescription",
            response.data.documentDescription
          );
          formik.setFieldValue("documentPath", response.data.documentPath);
        }
        setUpdateDoc("");
        dispatch(updateIsLoading(false));
      })
      .catch((err) => {
        setUpdateDoc("");
        dispatch(updateIsLoading(false));
      });
  };

  const deleteRecord = (arg: string | number, index: number) => {
    const obj = {
      assetId: 0,
      projectId: projectId,
      documentId: arg,
      deletedBy: 0,
    };
    dispatch(updateIsLoading(true));
    axiosInstance
      .delete("/projects/deletedocument", { data: obj })
      .then((response) => {
        if (response.status === 200) {
          setSnackBarOpen({
            ...snackBarOpen,
            value: true,
            message: response.data.message,
            type: snackBarColors.success,
          });
        }
        dispatch(updateIsLoading(false));
        getProjectDocumentsDetails();
      })
      .catch((err) => {
        setSnackBarOpen({
          ...snackBarOpen,
          value: true,
          message: err.response.data.message,
          type: snackBarColors.error,
        });
        dispatch(updateIsLoading(false));
      });
  };

  const downloadDoc = (arg: DownloadDocProps) => {
    const fileUrl = arg.documentPath;
    axiosInstance
      .get(fileUrl)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        return new Blob([response.data]);
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = arg.documentFileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const handleAttach = () => {
    setUpdateDoc("Create");
    setOpen(true);
    formik.setFieldValue("documentFileName", initialValues.documentFileName);
    formik.setFieldValue("documentType", initialValues.documentType);
    formik.setFieldValue("documentDescription", initialValues.documentDescription);
  };

  const userHandling = (arg: string[] | number[]) => {
    const removeDuplicatesSelections = [...new Set(arg as string[])];
    const concatCheckedSelections = [
      ...projectMember,
      ...removeDuplicatesSelections,
    ];
    const removeDuplicatesWithConcatinatedSelections = [
      ...new Set(concatCheckedSelections),
    ];
    const filteredUserDetails = userDetails.filter((user) =>
      (arg as string[]).includes(user.firstName)
    );
    const restructuredFilteredUserDetails = filteredUserDetails.map((item) => ({
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
      userEmail: item.userEmail,
    }));
    const updatedProjectTeamArray = [
      ...restructuredFilteredUserDetails,
      ...teamMemberDetails,
    ];
    const filteredFirstNames = updatedProjectTeamArray.map(
      ({ firstName }) => firstName
    );
    const filteredUpdatedProjectTeamArray = updatedProjectTeamArray.filter(
      ({ firstName }, index) => !filteredFirstNames.includes(firstName, index + 1)
    );
    setProjectTeamArrayList([...filteredUpdatedProjectTeamArray]);
    setProjectMember([...removeDuplicatesWithConcatinatedSelections]);
    formik.setFieldValue("projectTeam", [...updatedProjectTeamArray]);
  };

  const onUserRemove = (name: string) => {
    const filteredProjectMember = projectMember.filter(
      (user: string) => name !== user
    );
    const filteredProjectTeamArray = formik.values.projectTeam.filter(
      (user) => name !== user.firstName
    );
    setProjectMember([...filteredProjectMember]);
    setUserDetails([
      ...userDetails.map((item) => ({
        ...item,
        isChecked:
          filteredProjectMember.length > 0
            ? filteredProjectMember.includes(item.firstName)
            : false,
      })),
    ]);
    formik.setFieldValue("projectTeam", [...filteredProjectTeamArray]);
  };

  const handleRightsStratergy = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(event);
    if (event.target.value === "Tier1") {
      formik.setFieldValue("supportingSupplements", true);
      formik.setFieldValue("foreignLanguageTranslations", true);
      formik.setFieldValue("derivatives", true);
      formik.setFieldValue("reuseOutsideofOriginalProgram", true);
      formik.setFieldValue("reuseofParts", true);
      formik.setFieldValue("outofContextReuseAllowed", true);
      formik.setFieldValue("allMediaDistribution", true);
      formik.setFieldValue("printDistribution", true);
      formik.setFieldValue("digitalDistribution", true);
      formik.setFieldValue("territoriesWorldRights", true);
      formik.setFieldValue("territoriesNonWorldRights", false);
      formik.setFieldValue("territoriesWorldRightsExclNorthamerica", false);
      formik.setFieldValue("languagesAll", true);
      formik.setFieldValue(
        "promotionalUse",
        "Rights To Display In Context of Related LBPL Product"
      );
      formik.setFieldValue("numberofEditions", "This and Subsequent Editions");
      formik.setFieldValue("permittedUsers", "LBPL BUs only");
      formik.setFieldValue("distributionOthers", "");
      formik.setFieldValue("territoriesOthers", "");
      formik.setFieldValue("languagesOthers", "");
    } else if (event.target.value === "Tier2") {
      formik.setFieldValue("supportingSupplements", true);
      formik.setFieldValue("foreignLanguageTranslations", true);
      formik.setFieldValue("derivatives", true);
      formik.setFieldValue("reuseOutsideofOriginalProgram", true);
      formik.setFieldValue("reuseofParts", true);
      formik.setFieldValue("outofContextReuseAllowed", false);
      formik.setFieldValue("allMediaDistribution", true);
      formik.setFieldValue("printDistribution", true);
      formik.setFieldValue("digitalDistribution", true);
      formik.setFieldValue("territoriesWorldRights", true);
      formik.setFieldValue("territoriesNonWorldRights", false);
      formik.setFieldValue("territoriesWorldRightsExclNorthamerica", false);
      formik.setFieldValue("languagesAll", true);
      formik.setFieldValue(
        "promotionalUse",
        "Rights To Display In Context of Related LBPL Product"
      );
      formik.setFieldValue("numberofEditions", "This Edition only");
      formik.setFieldValue("permittedUsers", "LBPL BUs only");
      formik.setFieldValue("distributionOthers", "");
      formik.setFieldValue("territoriesOthers", "");
      formik.setFieldValue("languagesOthers", "");
    } else if (event.target.value === "Tier3") {
      formik.setFieldValue("supportingSupplements", false);
      formik.setFieldValue("foreignLanguageTranslations", false);
      formik.setFieldValue("derivatives", false);
      formik.setFieldValue("reuseOutsideofOriginalProgram", false);
      formik.setFieldValue("reuseofParts", false);
      formik.setFieldValue("outofContextReuseAllowed", false);
      formik.setFieldValue("allMediaDistribution", false);
      formik.setFieldValue("printDistribution", true);
      formik.setFieldValue("digitalDistribution", false);
      formik.setFieldValue("territoriesWorldRights", false);
      formik.setFieldValue("territoriesWorldRightsExclNorthamerica", false);
      formik.setFieldValue("territoriesNonWorldRights", true);
      formik.setFieldValue("languagesAll", false);
      formik.setFieldValue(
        "promotionalUse",
        "Rights To Display In Context of Related LBPL Product"
      );
      formik.setFieldValue("numberofEditions", "This Edition only");
      formik.setFieldValue("permittedUsers", "LBPL BUs only");
    }
  };

  const handleCheckBoxClick = (name: string, value: boolean) => {
    formik.setFieldValue(name, !value);
    if (name === "isPubDateTentative") {
      formik.setFieldValue("isPubDateTentative", true);
      formik.setFieldValue("isPubDateActual", false);
    } else if (name === "isPubDateActual") {
      formik.setFieldValue("isPubDateTentative", false);
      formik.setFieldValue("isPubDateActual", true);
    }
  };

  return (
    <>
      <SubHeading label="Update Project" />
      <div className="bg-white p-4 shadow-lg">
        <div
          className={`${colors.primaryBackground} text-white font-semibold px-4 py-2 text-sm`}>
          Project Details
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="flex items-center">
              <Input
                label="Project Name"
                name="projectName"
                onChange={formik.handleChange}
                value={formik.values.projectName.trimStart()}
                errors={formik.errors.projectName}
                required={true}
              />
            </div>
            <div className="flex items-center">
              <Dropdown
                label="Business Unit"
                name="businessUnit"
                onChange={formik.handleChange}
                value={
                  formik.values.businessUnit === "" ? "" : formik.values.businessUnit
                }
                errors={formik.errors.businessUnit}
                initialOption={{
                  id: "",
                  name: "",
                }}
                options={[
                  { id: "School", name: "School" },
                  { id: "Leads", name: "Leads" },
                ]}
                placeholder="Select"
                required={true}
              />
              <Input
                label="Project ID"
                name="projectCode"
                disabled
                value={formik.values.projectCode}
              />
            </div>
            <div className="flex items-center">
              <Input
                label="Legal Entry"
                name="legalEntity"
                onChange={formik.handleChange}
                value={formik.values.legalEntity}
                errors={formik.errors.legalEntity}
                required={true}
                disabled={true}
              />
              <Dropdown
                label="Project Status"
                name="projectStatus"
                onChange={formik.handleChange}
                value={
                  formik.values.projectStatus === ""
                    ? ""
                    : formik.values.projectStatus
                }
                errors={formik.errors.projectStatus}
                initialOption={{
                  id: "",
                  name: "",
                }}
                options={[
                  { id: "Active", name: "Active" },
                  { id: "Cancelled", name: "Cancelled" },
                  { id: "Complete", name: "Complete" },
                ]}
                placeholder="Select"
                required={true}
              />{" "}
            </div>
            <div className="flex items-center">
              <div>
                <div className="text-sm mb-1 mt-2 mx-2">
                  <label className="firstName text-zinc-900" data-testid="label">
                    Project Start Date <span className="text-[red]">*</span>
                  </label>
                </div>
                <div className="relative">
                  <Input
                    name="projectStart"
                    onChange={formik.handleChange}
                    value={
                      formik.values.projectStart !== null
                        ? sliceTime(formik.values.projectStart)
                        : null
                    }
                    errors={formik.errors.projectStart}
                    onClick={() => setIsStartCalenderOpen(true)}
                    onKeyDown={() => setIsStartCalenderOpen(true)}
                    autoComplete="off"
                    readOnly
                  />
                  <Calender
                    date={new Date()}
                    onDateChanged={(date) => {
                      formik.setFieldValue(
                        "projectStart",
                        `${
                          date.getFullYear() +
                          "-" +
                          ("0" + (date.getMonth() + 1)).slice(-2) +
                          "-" +
                          ("0" + date.getDate()).slice(-2)
                        }`
                      );
                      setIsStartCalenderOpen(false);
                    }}
                    isCalenderOpen={isStartCalenderOpen}
                    handleClickAway={() => setIsStartCalenderOpen(false)}
                  />
                </div>
              </div>
              <div>
                <div className="text-sm mb-1 mt-2 mx-2">
                  <label className="firstName text-zinc-900" data-testid="label">
                    Project End Date
                  </label>
                </div>
                <div className="relative">
                  <Input
                    name="projectEnd"
                    onChange={formik.handleChange}
                    value={
                      formik.values.projectEnd !== null
                        ? sliceTime(formik.values.projectEnd)
                        : null
                    }
                    errors={formik.errors.projectEnd}
                    onClick={() => setIsEndCalenderOpen(true)}
                    onKeyDown={() => setIsEndCalenderOpen(true)}
                    autoComplete="off"
                    readOnly
                  />
                  <Calender
                    date={new Date()}
                    onDateChanged={(date) => {
                      formik.setFieldValue(
                        "projectEnd",
                        `${
                          date.getFullYear() +
                          "-" +
                          ("0" + (date.getMonth() + 1)).slice(-2) +
                          "-" +
                          ("0" + date.getDate()).slice(-2)
                        }`
                      );
                      setIsEndCalenderOpen(false);
                    }}
                    isCalenderOpen={isEndCalenderOpen}
                    handleClickAway={() => setIsEndCalenderOpen(false)}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div>
                <div className="text-sm mb-1 mt-2 mx-2">
                  <label className="firstName text-zinc-900" data-testid="label">
                    Expected Payment Date
                  </label>
                </div>
                <div className="relative">
                  <Input
                    name="expectedPaymentDate"
                    onChange={formik.handleChange}
                    value={
                      formik.values.expectedPaymentDate !== null
                        ? sliceTime(formik.values.expectedPaymentDate)
                        : null
                    }
                    errors={formik.errors.expectedPaymentDate}
                    onClick={(e) => setIsExpectedCalenderOpen(true)}
                    onKeyDown={() => setIsExpectedCalenderOpen(true)}
                    autoComplete="off"
                    readOnly
                  />
                  <Calender
                    date={new Date()}
                    onDateChanged={(date) => {
                      formik.setFieldValue(
                        "expectedPaymentDate",
                        `${
                          date.getFullYear() +
                          "-" +
                          ("0" + (date.getMonth() + 1)).slice(-2) +
                          "-" +
                          ("0" + date.getDate()).slice(-2)
                        }`
                      );
                      setIsExpectedCalenderOpen(false);
                    }}
                    isCalenderOpen={isExpectedCalenderOpen}
                    handleClickAway={() => setIsExpectedCalenderOpen(false)}
                  />
                </div>
              </div>
              <Dropdown
                label="Payment Currency"
                name="paymentCurrency"
                onChange={formik.handleChange}
                value={
                  formik.values.paymentCurrency === ""
                    ? ""
                    : formik.values.paymentCurrency
                }
                errors={formik.errors.paymentCurrency}
                options={dropdownList.paymentCurrency}
                initialOption={{
                  id: "",
                  name: "",
                }}
                placeholder="Select"
                required={true}
              />
            </div>
            <div className="flex items-center">
              <TextArea
                label="General Comments"
                placeholder={"Enter Commends"}
                name="generalComments"
                onChange={formik.handleChange}
                value={formik.values.generalComments}
                errors={formik.errors.generalComments}
              />
              <Dropdown
                label="Right Stratergy"
                name="permissionsStrategy"
                onChange={handleRightsStratergy}
                value={
                  formik.values.permissionsStrategy === ""
                    ? ""
                    : formik.values.permissionsStrategy
                }
                errors={formik.errors.permissionsStrategy}
                initialOption={{
                  id: "",
                  name: "",
                }}
                options={[
                  { id: "Tier1", name: "Tier 1" },
                  { id: "Tier2", name: "Tier 2" },
                  { id: "Tier3", name: "Tier 3" },
                ]}
                placeholder="Select"
                required={true}
              />
            </div>
          </div>
          <div
            className={`${colors.primaryBackground} text-white font-semibold px-4 py-2 text-sm`}>
            Project Rights Required
          </div>
          <div className="text-sm">
            <div className="bg-gray-300 p-1 font-semibold mt-1">Rights</div>
            <div className="text-sm">
              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">
                  Supporting Supplements<span className="ml-1 text-[red]">*</span>
                </div>
                <RadioButton
                  size="small"
                  name="supportingSupplements"
                  value={"true"}
                  checked={formik.values.supportingSupplements === true}
                  onClick={() => handleRadioClick("supportingSupplements", true)}
                />
                <div>YES</div>
                <RadioButton
                  size="small"
                  name="supportingSupplements"
                  value={"false"}
                  checked={formik.values.supportingSupplements === false}
                  onClick={() => handleRadioClick("supportingSupplements", false)}
                />
                <div>NO</div>
                <div className="text-[red]">
                  {formik.values.supportingSupplements === null &&
                    formik.errors.supportingSupplements}
                </div>
              </div>
              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">
                  Foreign Language Translations
                  <span className="ml-1 text-[red]">*</span>
                </div>
                <RadioButton
                  size="small"
                  name="foreignLanguageTranslations"
                  value={"true"}
                  checked={formik.values.foreignLanguageTranslations === true}
                  onClick={() =>
                    handleRadioClick("foreignLanguageTranslations", true)
                  }
                />
                <div>YES</div>
                <RadioButton
                  size="small"
                  name="foreignLanguageTranslations"
                  value={"false"}
                  checked={formik.values.foreignLanguageTranslations === false}
                  onClick={() =>
                    handleRadioClick("foreignLanguageTranslations", false)
                  }
                />
                <div>NO</div>
                <div className="text-[red]">
                  {formik.values.foreignLanguageTranslations === null &&
                    formik.errors.foreignLanguageTranslations}
                </div>
              </div>
              <div>
                <Dropdown
                  label="Promotional Use"
                  name="promotionalUse"
                  isStringValue={true}
                  onChange={formik.handleChange}
                  value={
                    formik.values.promotionalUse === ""
                      ? ""
                      : formik.values.promotionalUse
                  }
                  errors={formik.errors.promotionalUse}
                  initialOption={{
                    id: 0,
                    name: "",
                  }}
                  options={dropdownList.promotionalUse}
                  placeholder="Select"
                />
                <Dropdown
                  label="Number of Editions"
                  name="numberofEditions"
                  isStringValue={true}
                  onChange={formik.handleChange}
                  value={
                    formik.values.numberofEditions === ""
                      ? ""
                      : formik.values.numberofEditions
                  }
                  errors={formik.errors.numberofEditions}
                  initialOption={{
                    id: "",
                    name: "",
                  }}
                  options={dropdownList.numberOfEditions}
                  placeholder="Select"
                  required={true}
                />
              </div>
              <div className="flex space-x-2 items-center my-1 ">
                {" "}
                <div className="w-1/4">
                  Derivatives<span className="ml-1 text-[red]">*</span>
                </div>
                <RadioButton
                  size="small"
                  name="derivatives"
                  value={"true"}
                  checked={formik.values.derivatives === true}
                  onClick={() => handleRadioClick("derivatives", true)}
                />
                <div>YES</div>
                <RadioButton
                  size="small"
                  name="derivatives"
                  value={"false"}
                  checked={formik.values.derivatives === false}
                  onClick={() => handleRadioClick("derivatives", false)}
                />
                <div>NO</div>
                <div className="text-[red]">
                  {formik.values.derivatives === null && formik.errors.derivatives}
                </div>
              </div>

              {formik.values.derivatives && (
                <div className="flex items-center my-2 space-x-4">
                  <CheckBox
                    name={"abridgements"}
                    checked={formik.values.abridgements}
                    onClick={() =>
                      formik.setFieldValue(
                        "abridgements",
                        !formik.values.abridgements
                      )
                    }
                  />
                  <div>Abridgements</div>

                  <CheckBox
                    name={"briefEditions"}
                    checked={formik.values.briefEditions}
                    onClick={() =>
                      formik.setFieldValue(
                        "briefEditions  ",
                        !formik.values.briefEditions
                      )
                    }
                  />
                  <div>Brief Editions</div>

                  <CheckBox
                    name={"splitEditions"}
                    checked={formik.values.splitEditions}
                    onClick={() =>
                      formik.setFieldValue(
                        "splitEditions",
                        !formik.values.splitEditions
                      )
                    }
                  />
                  <div>Split Editions</div>

                  <CheckBox
                    name={"adaptations"}
                    checked={formik.values.adaptations}
                    onClick={() =>
                      formik.setFieldValue("adaptations", !formik.values.adaptations)
                    }
                  />
                  <div>Adaptations </div>
                  <CheckBox
                    name={"customVersions"}
                    checked={formik.values.customVersions}
                    onClick={() =>
                      formik.setFieldValue(
                        "customVersions",
                        !formik.values.customVersions
                      )
                    }
                  />
                  <div>Custom Versions </div>
                  <CheckBox
                    name={"otherVersions"}
                    checked={formik.values.otherVersions}
                    onClick={() =>
                      formik.setFieldValue(
                        "otherVersions",
                        !formik.values.otherVersions
                      )
                    }
                  />
                  <div>Versions </div>
                </div>
              )}
              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">
                  Reuse outside of original program/course
                  <span className="ml-1 text-[red]">*</span>
                </div>
                <RadioButton
                  size="small"
                  name="reuseOutsideofOriginalProgram"
                  value={"true"}
                  checked={formik.values.reuseOutsideofOriginalProgram === true}
                  onClick={() =>
                    handleRadioClick("reuseOutsideofOriginalProgram", true)
                  }
                />
                <div>YES</div>
                <RadioButton
                  size="small"
                  name="reuseOutsideofOriginalProgram"
                  value={"false"}
                  checked={formik.values.reuseOutsideofOriginalProgram === false}
                  onClick={() =>
                    handleRadioClick("reuseOutsideofOriginalProgram", false)
                  }
                />
                <div>NO</div>
                <div className="text-[red]">
                  {formik.values.reuseOutsideofOriginalProgram === null &&
                    formik.errors.reuseOutsideofOriginalProgram}
                </div>
              </div>
              <div>
                <Dropdown
                  label="Permitted Users"
                  name="permittedUsers"
                  isStringValue={true}
                  onChange={formik.handleChange}
                  value={
                    formik.values.permittedUsers === ""
                      ? ""
                      : formik.values.permittedUsers
                  }
                  errors={formik.errors.permittedUsers}
                  initialOption={{
                    id: 0,
                    name: "",
                  }}
                  options={dropdownList.permittedUsers}
                  placeholder="Select"
                  required={true}
                />
              </div>
              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">
                  Reuse of Parts of the title containing the asset
                  <span className="ml-1 text-[red]">*</span>
                </div>
                <RadioButton
                  size="small"
                  name="reuseofParts"
                  value={"true"}
                  checked={formik.values.reuseofParts === true}
                  onClick={() => handleRadioClick("reuseofParts", true)}
                />
                <div>YES</div>
                <RadioButton
                  size="small"
                  name="reuseofParts"
                  value={"false"}
                  checked={formik.values.reuseofParts === false}
                  onClick={() => handleRadioClick("reuseofParts", false)}
                />
                <div>NO</div>
                <div className="text-[red]">
                  {formik.values.reuseofParts === null && formik.errors.reuseofParts}
                </div>
              </div>
              {formik.values.reuseofParts && (
                <div className="flex  items-center my-2">
                  <CheckBox
                    name={"chapterOrUnit"}
                    checked={formik.values.chapterOrUnit}
                    onClick={() =>
                      formik.setFieldValue(
                        "chapterOrUnit",
                        !formik.values.chapterOrUnit
                      )
                    }
                  />
                  <div>Chapter/Unit</div>
                  <CheckBox
                    name={"portionsOfChapterOrUnit"}
                    checked={formik.values.portionsOfChapterOrUnit}
                    onClick={() =>
                      formik.setFieldValue(
                        "portionsOfChapterOrUnit",
                        !formik.values.portionsOfChapterOrUnit
                      )
                    }
                  />
                  <div>Portions of a Chapter/Unit</div>
                </div>
              )}

              <div className="flex space-x-2 items-center my-2">
                {" "}
                <div className="w-1/4">
                  Out of Context Reuse Allowed
                  <span className="ml-1 text-[red]">*</span>
                </div>
                <RadioButton
                  size="small"
                  name="outofContextReuseAllowed"
                  value={"true"}
                  checked={formik.values.outofContextReuseAllowed === true}
                  onClick={() => handleRadioClick("outofContextReuseAllowed", true)}
                />
                <div>YES</div>
                <RadioButton
                  size="small"
                  name="outofContextReuseAllowed"
                  value={"true"}
                  checked={formik.values.outofContextReuseAllowed === false}
                  onClick={() => handleRadioClick("outofContextReuseAllowed", false)}
                />
                <div>NO</div>
                <div className="text-[red]">
                  {formik.values.outofContextReuseAllowed === null &&
                    formik.errors.outofContextReuseAllowed}
                </div>
              </div>
              <div className="bg-gray-300 p-1 font-semibold mt-1">Distribution</div>
              <div>
                <div className="flex items-center my-2">
                  <CheckBox
                    name={"allMediaDistribution"}
                    checked={formik.values.allMediaDistribution}
                    onClick={() => {
                      formik.setFieldValue(
                        "allMediaDistribution",
                        !formik.values.allMediaDistribution
                      );
                      formik.setFieldValue("distributionOthers", "");
                    }}
                  />
                  <div>All Media</div>
                  <CheckBox
                    name={"printDistribution"}
                    checked={formik.values.printDistribution}
                    onClick={() =>
                      formik.setFieldValue(
                        "printDistribution",
                        !formik.values.printDistribution
                      )
                    }
                  />
                  <div>Print</div>
                  <CheckBox
                    name={"digitalDistribution"}
                    checked={formik.values.digitalDistribution}
                    onClick={() =>
                      formik.setFieldValue(
                        "digitalDistribution",
                        !formik.values.digitalDistribution
                      )
                    }
                  />
                  <div>Digital</div>
                </div>
                <TextArea
                  label="Other(s)"
                  placeholder={"Specify Distribution"}
                  name="distributionOthers"
                  onChange={formik.handleChange}
                  value={formik.values.distributionOthers}
                  errors={formik.errors.distributionOthers}
                  disabled={formik.values.allMediaDistribution}
                />
              </div>
              <div className="bg-gray-300 p-1 font-semibold mt-1">Territories</div>
              <div className="flex items-center my-2">
                <RadioButton
                  size="small"
                  name="territoriesWorldRights"
                  value={"true"}
                  checked={formik.values.territoriesWorldRights}
                  onClick={() => {
                    handleTerritoriesRadioClick("territoriesWorldRights", true);
                    formik.setFieldValue("territoriesOthers", "");
                  }}
                />
                <div>World Rights</div>
                <RadioButton
                  size="small"
                  name="territoriesWorldRightsExclNorthamerica"
                  value={"true"}
                  checked={formik.values.territoriesWorldRightsExclNorthamerica}
                  onClick={() =>
                    handleTerritoriesRadioClick(
                      "territoriesWorldRightsExclNorthamerica",
                      true
                    )
                  }
                />
                <div>World Rights Excluding North America</div>
                <RadioButton
                  size="small"
                  name="territoriesNonWorldRights"
                  value={"true"}
                  checked={formik.values.territoriesNonWorldRights}
                  onClick={() =>
                    handleTerritoriesRadioClick("territoriesNonWorldRights", true)
                  }
                />
                <div>Non World Rights</div>
              </div>
              <TextArea
                label="Other(s)"
                placeholder={"Specify Territory"}
                name="territoriesOthers"
                onChange={formik.handleChange}
                value={formik.values.territoriesOthers}
                errors={formik.errors.territoriesOthers}
                disabled={formik.values.territoriesWorldRights}
              />
              <div className="bg-gray-300 p-1 font-semibold mt-1">Language(s)</div>
              <div className="flex items-center my-2">
                <CheckBox
                  name={"languagesAll"}
                  checked={formik.values.languagesAll}
                  onClick={() => {
                    formik.setFieldValue(
                      "languagesAll",
                      !formik.values.languagesAll
                    );
                    formik.setFieldValue("languagesOthers", "");
                  }}
                />
                <div>All</div>
              </div>
              <TextArea
                label="Other(s)"
                placeholder={"Enter Language"}
                name="languagesOthers"
                onChange={formik.handleChange}
                value={formik.values.languagesOthers}
                errors={formik.errors.languagesOthers}
                disabled={formik.values.languagesAll}
              />
            </div>
          </div>
          <div
            className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
            Project Team
          </div>
          <div className="flex space-x-96">
            <div className="text-sm flex items-center py-3 space-x-4">
              <div>
                <Input
                  placeholder="Search by User First Name"
                  name="fName"
                  value={fName}
                  onChange={(e) =>
                    !e.target.value.startsWith(" ") && setFName(e.target.value)
                  }
                  clearText={true}
                  onClearText={() => {
                    setFName("");
                    setIsUserData(false);
                  }}
                />
              </div>
              {fName !== "" ? (
                <div className="cursor-pointer mt-2" onClick={onUserSearch}>
                  <div className="border border-blue-700 w-20 h-8 flex items-center justify-center">
                    Search
                  </div>
                </div>
              ) : null}
            </div>
            {projectMember.length > 0 && (
              <div className="py-3">
                <div className="font-semibold">Team Members</div>
                {projectMember.map((member: string, index: number) => {
                  return (
                    <div className="flex items-center mt-3 space-x-5" key={index}>
                      <div className="w-5">
                        <i className="fa-solid fa-user"></i>
                      </div>
                      <div className="w-32">{member}</div>
                      <div className="w-10">
                        <FaTrashAlt
                          color="red"
                          className=" w-6 h-6 p-1 rounded-sm cursor-pointer"
                          onClick={() => onUserRemove(member)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="mt-5">
            {isUserData ? (
              <div>
                <div className="mb-4">
                  <DataTable
                    columns={userColumns}
                    rows={userDetails}
                    checkBoxShow={true}
                    tableHeading={"Project Team Member"}
                    primaryKey={"userId"}
                    checkBoxValue="firstName"
                    checkBoxHandling={userHandling}
                    totalRowsCount={0}
                    handleRowsPerPageChange={() => {
                      return null;
                    }}
                    handlePageChange={() => {
                      return null;
                    }}
                    rowsPerPage={0}
                    page={0}
                    sortingHandling={() => {
                      return null;
                    }}
                    sortColumn={false}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div
            className={`${colors.primaryBackground} text-white px-4 py-2 font-semibold flex items-center justify-between mt-2`}>
            <div className="pl-1">Title Details</div>
            <div
              className="bg-white text-[blue] px-2 py-1 border border-[blue] uppercase text-sm cursor-pointer"
              onClick={addNewTitle}>
              Add Related Title
            </div>
          </div>
          <div className="flex items-center space-x-4 py-5">
            <div>
              <Input
                placeholder="Search by Title Code or ISBN"
                name="titleCodeIsbn"
                value={titleCodeIsbn}
                onChange={(e) =>
                  !e.target.value.startsWith(" ") && setTitleCodeIsbn(e.target.value)
                }
                clearText={true}
                onClearText={() => {
                  setTitleCodeIsbn("");
                }}
              />
            </div>
            {titleCodeIsbn !== "" && (
              <div className="cursor-pointer mt-2" onClick={() => relatedTitle()}>
                <div className="border border-blue-700 w-20 h-8 flex items-center justify-center">
                  Search
                </div>
              </div>
            )}
          </div>
          {openAddRelatedTitle ? (
            <div className="border mt-5 rounded-sm p-2">
              <div className="text-sm font-semibold uppercase">
                Add Related Title
              </div>
              <div>
                <div className="flex items-center">
                  <Input
                    label="Title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    required={true}
                  />
                  <Input
                    label="Title Code"
                    name="titleCode"
                    value={formik.values.titleCode}
                    disabled={true}
                  />
                  {newTitle === "newtitle" && (
                    <Input
                      label="Author (s)"
                      name="newTitleAuthors"
                      value={formik.values.newTitleAuthors}
                      onChange={formik.handleChange}
                    />
                  )}
                  {newTitle === "" && (
                    <div className="multi-select mt-2 mx-2">
                      <div className="mb-1 text-sm">
                        <label className="text-zinc-900">
                          Author (s) <span className="text-[red]">*</span>
                        </label>
                      </div>
                      <Select
                        isMulti={true}
                        onSelectHandler={(args) => {
                          formik.setFieldValue("authors", args);
                        }}
                        options={authorsMenuList}
                        width="w-[300px]"
                        defaultvalue={authorsMenuList}
                      />
                    </div>
                  )}
                  {newTitle === "edit" && (
                    <div className="multi-select mt-2 mx-2">
                      <div className="mb-1 text-sm">
                        <label className="text-zinc-900">
                          Author (s) <span className="text-[red]">*</span>
                        </label>
                      </div>
                      <Select
                        isMulti={true}
                        onSelectHandler={(args) => {
                          formik.setFieldValue("authors", args);
                        }}
                        options={editAuthorsMenuList}
                        width="w-[300px]"
                        defaultvalue={editAuthorsMenuList}
                      />
                    </div>
                  )}
                </div>
                <div className="flex items-center">
                  <Input
                    label="Author Contract Number"
                    name="authorContractNumber"
                    value={formik.values.authorContractNumber}
                    onChange={formik.handleChange}
                  />
                  <div>
                    <div className="text-sm mb-1 mt-2 mx-2">
                      <label className="firstName text-zinc-900" data-testid="label">
                        Pub Date<span className="text-[red]">*</span>
                      </label>
                    </div>
                    <div className="relative">
                      <Input
                        name="pubDate"
                        onChange={formik.handleChange}
                        value={formik.values.pubDate}
                        onClick={() => setIsPubDateCalenderOpen(true)}
                        onKeyDown={() => setIsPubDateCalenderOpen(true)}
                        autoComplete="off"
                        readOnly
                      />
                      <Calender
                        date={new Date()}
                        onDateChanged={(date) => {
                          formik.setFieldValue(
                            "pubDate",
                            `${
                              date.getFullYear() +
                              "-" +
                              ("0" + (date.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + date.getDate()).slice(-2)
                            }`
                          );
                          setIsPubDateCalenderOpen(false);
                        }}
                        isCalenderOpen={isPubDateCalenderOpen}
                        handleClickAway={() => setIsPubDateCalenderOpen(false)}
                      />
                    </div>
                  </div>
                  <div className="flex items-center mt-6 ml-3">
                    <div>Tenative</div>
                    <CheckBox
                      name="isPubDateTentative"
                      checked={formik.values.isPubDateTentative}
                      onClick={() =>
                        handleCheckBoxClick(
                          "isPubDateTentative",
                          formik.values.isPubDateTentative
                        )
                      }
                    />
                    <div>Actual</div>
                    <CheckBox
                      name="isPubDateActual"
                      checked={formik.values.isPubDateActual}
                      onClick={() =>
                        handleCheckBoxClick(
                          "isPubDateActual",
                          formik.values.isPubDateActual
                        )
                      }
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <Input
                    label="Edition Number"
                    name="editionNumber"
                    value={formik.values.editionNumber}
                    onChange={formik.handleChange}
                  />
                  <Dropdown
                    label="Content Type"
                    name="contentType"
                    isStringValue={true}
                    value={
                      formik.values.contentType === ""
                        ? ""
                        : formik.values.contentType
                    }
                    onChange={formik.handleChange}
                    initialOption={{
                      id: "",
                      name: "",
                    }}
                    options={dropdownList.contentType}
                    placeholder="Select"
                    required={true}
                  />
                  <Dropdown
                    label="Identifier Type"
                    name="identifierType"
                    isStringValue={true}
                    value={
                      formik.values.identifierType === ""
                        ? ""
                        : formik.values.identifierType
                    }
                    onChange={formik.handleChange}
                    initialOption={{
                      id: "",
                      name: "",
                    }}
                    options={dropdownList.identifierType}
                    placeholder="Select"
                    required={true}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex">
                    <Input
                      label="Identifier"
                      name="identifier"
                      value={formik.values.identifier}
                      onChange={formik.handleChange}
                      required={true}
                      maxLength={13}
                    />
                    <Dropdown
                      label="Parent Identifier Type"
                      name="parentIdentifierType"
                      isStringValue={true}
                      value={
                        formik.values.parentIdentifierType === ""
                          ? ""
                          : formik.values.parentIdentifierType
                      }
                      onChange={formik.handleChange}
                      initialOption={{
                        id: "",
                        name: "",
                      }}
                      options={dropdownList.identifierType}
                      placeholder="Select"
                    />
                    <Input
                      label="Parent Identifier"
                      name="parentIdentifier"
                      value={formik.values.parentIdentifier}
                      onChange={formik.handleChange}
                      maxLength={13}
                    />
                  </div>
                </div>
                <div className="flex justify-end space-x-2 py-5">
                  <Button
                    title={`${newTitle === "edit" ? "Update" : "Add"}`}
                    type={"button"}
                    color={"normal"}
                    onClick={() => addRelatedTitle()}
                  />
                  <Button
                    title={"Cancel"}
                    type={"button"}
                    color={"normal"}
                    onClick={() => setOpenAddRelatedTitle(false)}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="py-2">
            {noProjectTitle === "" ? (
              <DataTable
                columns={titleDetailsColumns}
                rows={projectTitles}
                showDelete={true}
                showView={false}
                showEdit={true}
                checkBoxShow={false}
                onEditHandler={onTitleEdit}
                onDeleteHandler={deleteProjectTitle}
                primaryKey="titleId"
                rowsPerPage={titleRowsPerPage}
                totalRowsCount={totalTitleRowsCount}
                sortColumn={false}
                page={titlePage}
                handlePageChange={handleTitlePageChange}
                handleRowsPerPageChange={handleTitleRowsPerPageChange}
                sortingHandling={() => {
                  return null;
                }}
                sortColumns={["All"]}
              />
            ) : (
              <div className="text-red-600">No Titles Available</div>
            )}
          </div>
          <div
            className={`${colors.primaryBackground} text-white px-4 py-2 font-semibold flex items-center justify-between`}>
            <div className="pl-1">Documents</div>
            <div
              className="bg-white text-[blue] px-2 py-1 border border-[blue] uppercase text-sm cursor-pointer"
              onClick={() => handleAttach()}>
              Attach
            </div>
          </div>
          <div className="py-2">
            {noProjectDoc === "" ? (
              <DataTable
                columns={documentColumns}
                rows={projectDocs}
                showDelete={true}
                showView={false}
                showEdit={true}
                checkBoxShow={false}
                showDownload={true}
                onEditHandler={onDocEdit}
                onDeleteHandler={deleteRecord}
                onDownloadHandler={downloadDoc}
                primaryKey="documentId"
                rowsPerPage={titleRowsPerPage}
                totalRowsCount={totalTitleRowsCount}
                sortColumn={false}
                page={titlePage}
                handlePageChange={handleTitlePageChange}
                handleRowsPerPageChange={handleTitleRowsPerPageChange}
                sortingHandling={() => {
                  return null;
                }}
                sortColumns={["All"]}
              />
            ) : (
              <div className="text-red-600">No Documents Available</div>
            )}
          </div>
          <div className="flex justify-center space-x-2 py-5">
            <Button title={"Update"} type={"submit"} color={"primary"} />
            <Button
              title={"Cancel"}
              type={"button"}
              color={"danger"}
              onClick={() => navigate("/title/projects_home")}
            />
          </div>
        </form>
      </div>
      <div>
        {open ? (
          <AlertDialog open={open} handleClose={handleClose}>
            {attachDocDialog()}
          </AlertDialog>
        ) : null}
      </div>
      <div>
        <SnackBar
          isOpen={snackBarOpen.value}
          handleClose={() => setSnackBarOpen({ ...snackBarOpen, value: false })}
          message={snackBarOpen.message}
          type={snackBarOpen.type}
        />
      </div>
    </>
  );
};

export default UpdateProject;
