import React, { InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: any;
  name?: string;
  type?: string;
  title?: string;
  errors?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  clearText?: boolean;
  onClearText?: () => void;
  required?: boolean;
  maxLength?: number;
}

const Input = (props: InputProps) => {
  const {
    onChange,
    value,
    name,
    type,
    errors,
    label,
    placeholder,
    disabled,
    width,
    height,
    clearText,
    onClearText,
    required,
    maxLength,
    ...rest
  } = props;
  return (
    <div className="mt-2 mx-2 text-md">
      <div className="text-sm mb-1">
        {label && (
          <label className="firstName text-zinc-900" data-testid="label">
            {label}
            {required && <span className="ml-1 text-[red]">*</span>}
          </label>
        )}
      </div>
      <div className={`flex items-center ${type !== "radio" && "border"}`}>
        {" "}
        <div className={`${clearText && value !== "" ? "relative" : ""}`}>
          <input
            data-testid="input"
            {...rest}
            name={name}
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            className={`${width ? width : "min-w-[300px]"} ${
              height ? height : "h-8"
            } rounded-sm outline-none pl-2 text-sm ${disabled && "bg-gray-400"} ${
              clearText && value !== "" && "pr-7"
            }`}
          />
          {clearText && value !== "" ? (
            <div
              data-testid="oncleartext"
              className="h-full flex items-center justify-center absolute right-2 top-0 text-lg cursor-pointer"
              onClick={onClearText}>
              <i className="fa-solid fa-xmark"></i>
            </div>
          ) : null}
        </div>
      </div>
      {errors ? (
        <div className="text-sm text-[red]" data-testid="error">
          {errors}
        </div>
      ) : null}
    </div>
  );
};

export default Input;
