import React from "react";
import { colors } from "../../config/colors/colors";

interface SubHeadingProps {
  label: string;
}

const SubHeading = (props: SubHeadingProps) => {
  return (
    <div
      className={`font-bold pb-1 pl-4 text-[26px] ${colors.primaryText} font-sans`}
      data-testid="label-wrapper">
      {props.label}
    </div>
  );
};

export default SubHeading;
